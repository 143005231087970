var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('transition', [_c('div', {
    class: [`warning ${_vm.newsBarClass}`, {
      centered: _vm.centered
    }]
  }, [_c('img', {
    staticClass: "icon",
    attrs: {
      "src": require(`@/assets/images/icons/news.svg`),
      "alt": "News"
    }
  }), _c('p', {
    staticClass: "warning__text"
  }, [_vm._v(_vm._s(_vm.text))]), _vm.textBtn ? _c('a', {
    staticClass: "btn-outline btn-width-auto news-btn",
    attrs: {
      "href": _vm.link,
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.textBtn) + " ")]) : _vm._e(), _c('img', {
    staticClass: "close-btn",
    attrs: {
      "src": require(`@/assets/images/icons/cross.svg`),
      "alt": "Close"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close');
      }
    }
  })])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };