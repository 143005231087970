export function toExportFormat(result, columns) {
    let data = [];
    if (result.data) {
        data = result.data;
    } else if (result.items) {
        data = result.items;
    }

    const titleNames = columns.map((columnRaw) => {
        const { name, title } = columnRaw;
        return { name, title };
    });

    let option = {};

    const dataset = data.map((el) => {
        let object = {};
        titleNames.forEach((item) => {
            object[item.name] = el[item.name];
        });
        return object;
    });

    option.type = "excel";

    option.datas = [
        {
            sheetData: dataset,
            sheetName: "Page 1",
            sheetFilter: Object.keys(dataset[0]),
            sheetHeader: titleNames.map((item) => item.title),
        },
    ];

    return option;
}
