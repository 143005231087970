import Vue from "vue";
export default {
    namespaced: true,
    actions: {

        //*===================================
        async getRrcLimit(context, {fake}) {
            //const { data } = await Vue.api.get("/priceVar/getRrcLimit", {
            const { data } = await Vue.api.get("/priceVar/getRrcLimit", {
                params: {
                    fake
                },
            });
            return data;
        },

        //*===================================
        async getPriceVar(context, { id }) {
            const { data } = await Vue.api.get("/priceVar/getPriceVar", {
                params: {
                    id,
                },
            });
            return data;
        },


        //*========================================================================
        async addPriceVar(context, { title, entity, monitor, mp, description, variation, shedule}) {
            const { data: responseData } = await Vue.api.post(
                "/priceVar/addPriceVar",
                {
                    title,
                    entity,
                    monitor,
                    mp,
                    description,
                    variation,
                    shedule
                }
            );
            return responseData;
        },


        //*==========================================================
        async updatePriceVar(context, {
            id,
            title,
            description,
            monitor,
            variation,
            shedule,
        }) {
            const { data: responseData } = await Vue.api.put(
                "/priceVar/updatePriceVar",
                {
                    id,
                    title,
                    description,
                    monitor,
                    variation,
                    shedule,
                }
            );
            return responseData;
        },


        //*======================================
        async removePriceVar(context, { id }) {
            const { data: responseData } = await Vue.api.delete(
                "/priceVar/removePriceVar",
                {
                    params: {
                        id,
                    },
                }
            );
            return responseData;
        },

        //*==================================================
        async removePriceVarItems(context, { id, items }) {
            const { data: responseData } = await Vue.api.delete(
                "/priceVar/removePriceVarItems",
                {
                    params: {
                        id,
                        items,
                    },
                }
            );
            return responseData;
        },


        //*============================================
        async priceVarImport(context, { id, lines }) {
            const { data: responseData } = await Vue.api.post(
                "/priceVar/priceVarImport",
                {
                    id,
                    lines,
                }
            );
            return responseData;
        },



        //*============================================
        async getPriceVarList(context, queryParams) {

            ////console.log('getPriceVarList(): queryParams=', queryParams)

            const { data } = await Vue.api.get("/priceVar/getPriceVarList", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },


        //*================================================
        async getPriceVarProducts(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/priceVar/getPriceVarProducts", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },


        //*===============================================================
        async createPriceVarRefreshRequest(ctx,
                { //hash,
                  parameters, aSku }) {

            //console.log('createPriceVarRefreshRequest(): hash=',hash, ' parameters=',parameters, ' aSku=',aSku)
            //console.log('createPriceVarRefreshRequest(): parameters=',parameters, ' aSku=',aSku)

            //const { data } = await Vue.api.get(
            const { data } = await Vue.api.post(
                //"/priceVar/createPriceVarRefreshRequest",
                "/priceVar/createPriceVarRefreshRequest?id="+parameters['id'],
                {
                    ////params: {
                        //hash,
                        ... parameters,
                        aSku,
                    ////}
                }
            );
            return data;
        },

        //*===============================================
        async getPriceVarRefreshProgress(ctx, { hash }) {
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarRefreshProgress",
                {
                    params: {
                        hash,
                    }
                }
            );
            return data;
        },

        //*===============================================
        /*
        async importRRC(context, { id, file }) {
            let form_data = new FormData();
            form_data.append("id", id);
            form_data.append("files", file);
            const { data } = await Vue.api.post(
                "/priceVar/importRRC",
                form_data,
            );
            return data;
        },
        */
        async importRRC(context, { id, aSkuRrc }) {
            const { data } = await Vue.api.post(
                "/priceVar/importRRC",
                {
                    id,
                    aSkuRrc,
                }
            );
            return data;
        },

        async importRRCProgress(context, { id }) {
            const { data } = await Vue.api.get(
                "/priceVar/importRRCProgress",
                {
                    params: {
                        id,
                    },
                }
            );
            return data;
        },


        /*==============================================================
        async getPriceVarProductsPriceVarDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarProductsPriceVarDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },


        /*===========================================
        async getPriceVarBrands(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/priceVar/getPriceVarBrands", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getPriceVarBrands_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/priceVar/getPriceVarBrands_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            *|

        async getPriceVarBrandsNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarBrandsNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getPriceVarBrandsNewSellersDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarBrandsNewSellersDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },

        //*============================================
        async getPriceVarSellers(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/priceVar/getPriceVarSellers", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getPriceVarSellers_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/priceVar/getPriceVarSellers_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            *|

        async getPriceVarSellersNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarSellersNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getPriceVarSellersNewBrandsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarSellersNewBrandsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },

        //*===============================================
        async getPriceVarCategories(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/priceVar/getPriceVarCategories", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getPriceVarCategories_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/priceVar/getPriceVarCategories_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            *|

        async getPriceVarCategoriesNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarCategoriesNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getPriceVarCategoriesNewBrandsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarCategoriesNewBrandsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getPriceVarCategoriesNewSellersDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/priceVar/getPriceVarCategoriesNewSellersDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        */


    },
};
