import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getList(context, { id }) {
            const { data } = await Vue.api.get("/list/getList", {
                params: {
                    id,
                },
            });
            return data;
        },
        async addList(context, { title, entity, mp, description }) {
            const { data: responseData } = await Vue.api.post("/list/addList", {
                title,
                entity,
                mp,
                description,
            });
            return responseData;
        },
        async updateList(context, { id, title, description }) {
            const { data: responseData } = await Vue.api.put(
                "/list/updateList",
                {
                    id,
                    title,
                    description,
                }
            );
            return responseData;
        },
        async removeList(context, { id }) {
            const { data: responseData } = await Vue.api.delete(
                "/list/removeList",
                {
                    params: {
                        id,
                    },
                }
            );
            return responseData;
        },
        async listImport(context, { id, lines }) {
            const { data: responseData } = await Vue.api.post(
                "/list/listImport",
                {
                    id,
                    lines,
                }
            );
            return responseData;
        },
        async removeListItems(context, { id, items }) {
            const { data: responseData } = await Vue.api.delete(
                "/list/removeListItems",
                {
                    params: {
                        id,
                        items,
                    },
                }
            );
            return responseData;
        },
        async getLists(context, queryParams) {
            const { data } = await Vue.api.get("/list/getLists", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },
        async getListCategories(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/list/getListCategories", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getListProducts(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/list/getListProducts", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getListBrands(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/list/getListBrands", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getListSellers(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/list/getListSellers", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
