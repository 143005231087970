import ModalSelfDemo from "@/components/modals/ModalSelfDemo.vue";
import ModalSelfDemoSuccess from "@/components/modals/ModalSelfDemoSuccess.vue";
export default {
  name: "Warning",
  components: {
    ModalSelfDemo,
    ModalSelfDemoSuccess
  },
  //created() {
  mounted() {
    //console.log('SelfDemo.41: this.$route.query.activateDemo=',this.$route.query.activateDemo)

    if (0 &&
    // ##SELF-DEMO-OFF##
    this.$route.query.activateDemo) {
      this.openModal();
    }
  },
  methods: {
    openModal() {
      if (!this.$access.user) {
        this.$modal.open(() => import("@/components/modals/access/ModalRegister"), {
          message: "Для активации демо-доступа создайте аккаунт"
        });
      } else {
        this.$refs.ModalSelfDemo.open();
      }
    },
    onCompleted() {
      this.$refs.ModalSelfDemoSuccess.open();
    },
    onCompletedSuccess() {
      this.$emit("activated");
      window.location.reload();
    }
  }
};