import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getBrand(context, { b_id, mp, date, date2, fbs }) {
            const { data } = await Vue.api.get("/getBrand", {
                params: {
                    mp,
                    date,
                    date2,
                    b_id,
                    fbs,
                },
            });
            return data;
        },
        async getBrandByName(context, { query, mp }) {
            const { data } = await Vue.api.get("/getBrandByName", {
                params: {
                    query,
                    mp,
                },
            });
            return data;
        },

        async brandOverviewAll(context, queryParams) {
            const { data } = await Vue.api.get("/brandOverviewAll", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },
        /*
        async brandOverviewAll(context, { mp, b_id, date, date2, fbs }) {
            const { data } = await Vue.api.get("/brandOverviewAll", {
                params: {
                    mp,
                    b_id,
                    date,
                    date2,
                    fbs,
                    // like _CH_DROP_CACHE_
                    ...queryParams,
                },
            });
            return data;
        },
        */

        async getBrandProducts(context, queryParams) {
            const { mp, b_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getBrandProducts", {
                params: {
                    mp,
                    b_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getBrandSellers(context, queryParams) {
            const { mp, b_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getBrandSellers", {
                params: {
                    mp,
                    b_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getBrandCategories(context, queryParams) {
            const { mp, b_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getBrandCategories", {
                params: {
                    mp,
                    b_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getBrandSegments(context, queryParams) {
            const { mp, b_id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/getBrandSegments", {
                params: {
                    mp,
                    b_id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
    },
};
