/* unplugin-vue-components disabled */import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=49e2c551&scoped=true"
import script from "./Spinner.vue?vue&type=script&lang=js"
export * from "./Spinner.vue?vue&type=script&lang=js"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=49e2c551&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e2c551",
  null
  
)

export default component.exports