var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.dark ? _c('div', {
    staticClass: "loader dark"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]) : _c('div', {
    staticClass: "loader"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
};
var staticRenderFns = [];
export { render, staticRenderFns };