var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.user ? _c('div', {
    staticClass: "user-wrapper",
    class: [{
      white: _vm.white
    }, _vm.position]
  }, [_c('div', {
    staticClass: "user activator",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return (() => _vm.userPopup = !_vm.userPopup).apply(null, arguments);
      }
    }
  }, [_vm.white ? _c('img', {
    attrs: {
      "src": require(`@/assets/images/header/user-white.svg`),
      "alt": "User"
    }
  }) : _c('img', {
    attrs: {
      "src": require(`@/assets/images/header/user.svg`),
      "alt": "User"
    }
  }), _c('span', {
    staticClass: "user-email"
  }, [_vm._v(" " + _vm._s(_vm.user.user_email_address) + " ")]), _vm.white ? _c('img', {
    attrs: {
      "src": require(`@/assets/images/header/arrow-white.svg`)
    }
  }) : _c('img', {
    attrs: {
      "src": require(`@/assets/images/header/arrow.svg`)
    }
  })]), _c('slide-y-up-transition', [_vm.userPopup ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: () => _vm.userPopup = false,
      expression: "() => (userPopup = false)"
    }],
    staticClass: "user-popup"
  }, [_c('div', {
    staticClass: "popup-header"
  }, [_c('div', {
    staticClass: "user"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/header/user.svg`),
      "alt": "User"
    }
  }), _c('span', {
    staticClass: "user-email"
  }, [_vm._v(" " + _vm._s(_vm.user.user_email_address) + " ")])])]), _c('div', {
    staticClass: "user-popup-line"
  }), _c('div', [_c('ul', {
    staticClass: "nav"
  }, [_c('li', [_c('router-link', {
    attrs: {
      "to": "/profile/settings"
    },
    nativeOn: {
      "click": function ($event) {
        return (() => _vm.userPopup = false).apply(null, arguments);
      }
    }
  }, [_vm._v("Настройки аккаунта")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/profile/plan"
    },
    nativeOn: {
      "click": function ($event) {
        return (() => _vm.userPopup = false).apply(null, arguments);
      }
    }
  }, [_vm._v("Тариф и оплата")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/profile/history"
    },
    nativeOn: {
      "click": function ($event) {
        return (() => _vm.userPopup = false).apply(null, arguments);
      }
    }
  }, [_vm._v("История запросов")])], 1), _c('li', [_c('router-link', {
    attrs: {
      "to": "/profile/partner"
    },
    nativeOn: {
      "click": function ($event) {
        return (() => _vm.userPopup = false).apply(null, arguments);
      }
    }
  }, [_vm._v("Партнерская программа")])], 1)])]), _c('button', {
    staticClass: "btn-outline user-popup-btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.onSignOut.apply(null, arguments);
      }
    }
  }, [_vm._v(" Выйти ")])]) : _vm._e()])], 1) : _vm._e();
};
var staticRenderFns = [];
export { render, staticRenderFns };