import "core-js/modules/es.array.push.js";
import colors from "@/utils/colors";
export default {
  model: {
    prop: "modelValue",
    event: "change"
  },
  props: {
    value: {
      type: [Boolean, String]
    },
    modelValue: {
      default: false
    },
    title: {
      type: String
    },
    // Мы установили `true-value` и `false-value` в true и false по-умолчанию, таким образом
    // мы всегда можем использовать их вместо проверки на то, установлены они или нет.
    // Также здесь мы можем использовать camelCase, дефис разделяющий имя атрибута
    // все равно будет работать
    trueValue: {
      default: true
    },
    falseValue: {
      default: false
    },
    rounded: {
      type: Boolean,
      default: false
    },
    wrapper: {
      type: String,
      default: "label"
    },
    color: {
      type: String,
      default: "#316D92"
    },
    centered: {
      type: Boolean,
      default: true
    },
    padding: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorLocal() {
      var _colors$this$color;
      if (this.color === "#316D92" && this.$store.getters.themeClass === "dark") {
        return "#6200ee";
      }
      if (this.color.startsWith("#")) {
        return this.color;
      }
      return ((_colors$this$color = colors[this.color]) === null || _colors$this$color === void 0 ? void 0 : _colors$this$color.color) || "#316D92";
    },
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }
      // Обратите внимание, что `true-value` и` false-value` являются camelCase в JS
      return this.modelValue === this.trueValue;
    }
  },
  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;
      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];
        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }
        this.$emit("change", newValue);
      } else {
        this.$emit("change", isChecked ? this.trueValue : this.falseValue);
      }
      return false;
    }
  }
};