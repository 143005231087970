import { plural } from "./numbers";

export const ENTITIES = {
    category: {
        // единств, именительный падеж, кто? что?
        one: "категория",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "категории",
        // единств, дательный падеж, кому? чему?
        oneDative: "категории",
        // единств, винительный падеж, кого? что?
        oneAccusative: "категорию",
        // множ, именительный падеж, кто? что?
        many: "категории",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "категорий",
        // множ, дательный падеж, кому? чему?
        manyDative: "категориям",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "female",
        metrics: {
            sold: {
                title: "Продажи",
                dative: "продажам",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "Выручка",
                dative: "выручке",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "Товаров",
                dative: "товарам",
                color: "blue",
            },
            products_with_sales: {
                title: "Товаров с продажами",
                dative: "товарам с продажами",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                ////title: `Выручка на товар`,
                title: `Средний чек`,
                dative: "выручке на товар",
                color: "orange",
                type: "money",
            },
            sellers: {
                title: "Продавцов",
                dative: "продавцам",
                color: "fiolet",
                columnWidth: 140,
            },
            sellers_with_sales: {
                title: "Продавцов с продажами",
                dative: "продавцам с продажами",
                percentOf: "sellers",
                color: "pink",
                columnWidth: 140,
            },
            brands: {
                title: "Брендов",
                dative: "брендам",
                color: "green",
            },
            brands_with_sales: {
                title: "Брендов с продажами",
                dative: "брендам с продажами",
                percentOf: "brands",
                color: "brown",
                columnWidth: 140,
            },
            /* //kwd
                keywords: {
                    title: 'Поисковых запросов',
                    dative: 'поисковым запросам',
                    color: 'blue',
                    columnWidth: 140
                },
                */
            avg_sold: {
                title: "Сред. продаж в день",
                dative: "среднему продаж в день",
                type: "sales",
                color: "lightyellow",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "Сред. выручка в день",
                dative: "средней выручке в день",
                type: "money",
                color: "lightyellow",
                columnWidth: 140,
            },
            /* //&:
            losses: {
                title: "Упущенная выручка",
                dative: "упущенной выручке",
                type: "money",
                color: "pink",
            },
            */
        },
    },

    // KaE
    keyword: {
        // единств, именительный падеж, кто? что?
        one: "поисковый запрос",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "поискового запроса",
        // единств, дательный падеж, кому? чему?
        oneDative: "поисковому запросу",
        // единств, винительный падеж, кого? что?
        oneAccusative: "поискового запроса",
        // множ, именительный падеж, кто? что?
        many: "поисковые запросы",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "поисковых запросов",
        // множ, дательный падеж, кому? чему?
        manyDative: "поисковым запросам",
        // поле сущности, в котором содержится текст название
        textProp: "keyword",
        // поле сщуности, в котором содержится идентификатор
        idProp: "k_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            sold: {
                title: "Продажи",
                dative: "продажам",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "Выручка",
                dative: "выручке",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "Товаров",
                dative: "товарам",
                color: "blue",
            },
            products_with_sales: {
                title: "Товаров с продажами",
                dative: "товарам с продажами",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                ////title: `Выручка на товар`,
                title: `Средний чек`,
                dative: "выручке на товар",
                color: "orange",
                type: "money",
            },
            sellers: {
                title: "Продавцов",
                dative: "продавцам",
                color: "fiolet",
                columnWidth: 140,
            },
            sellers_with_sales: {
                title: "Продавцов с продажами",
                dative: "продавцам с продажами",
                percentOf: "sellers",
                color: "pink",
                columnWidth: 140,
            },
            brands: {
                title: "Брендов",
                dative: "брендам",
                color: "green",
            },
            brands_with_sales: {
                title: "Брендов с продажами",
                dative: "брендам с продажами",
                percentOf: "brands",
                color: "brown",
                columnWidth: 140,
            },
            avg_sold: {
                title: "Сред. продаж в день",
                dative: "среднему продаж в день",
                type: "sales",
                color: "lightyellow",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "Сред. выручка в день",
                dative: "средней выручке в день",
                type: "money",
                color: "lightyellow",
                columnWidth: 140,
            },
        },
    },

    shopWb: {
        // единств, именительный падеж, кто? что?
        one: "магазин",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "магазина",
        // единств, дательный падеж, кому? чему?
        oneDative: "магазину",
        // единств, винительный падеж, кого? что?
        oneAccusative: "магазин",
        // множ, именительный падеж, кто? что?
        many: "магазины",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "магазинов",
        // множ, дательный падеж, кому? чему?
        manyDative: "магазинам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            total_orders: {
                title: "Кол-во заказов",
                dative: "кол-ву заказов",
                color: "red",
                type: "sales",
            },
            sum_orders: {
                title: "Сумма заказов",
                dative: "сумме заказов",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            total_sales: {
                title: "Кол-во продаж",
                dative: "кол-ву продаж",
                color: "blue",
                type: "sales",
            },
            sum_finished_price_sales: {
                title: "Сумма продаж",
                dative: "суммам продажа",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            total_return_sales: {
                title: "Кол-во возвратов",
                dative: "кол-ву возвратов",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            sum_finished_price_return_sales: {
                title: "Сумма возвратов",
                dative: "суммам возвратов",
                color: "fiolet",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks: {
                title: "Капитализация склада",
                dative: "капитализации складов",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks_cost_price: {
                title: "Себестоимость склада",
                dative: "себестоимости склада",
                color: "green",
                type: "money",
            },
            sum_comission_sales: {
                title: "Сумма комиссий",
                dative: "сумме комиссий",
                color: "brown",
                type: "money",
            },
            sum_revenue_sales: {
                title: "Выручка",
                dative: "выручке",
                type: "money",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    shopOzon: {
        // единств, именительный падеж, кто? что?
        one: "магазин",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "магазина",
        // единств, дательный падеж, кому? чему?
        oneDative: "магазину",
        // единств, винительный падеж, кого? что?
        oneAccusative: "магазин",
        // множ, именительный падеж, кто? что?
        many: "магазины",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "магазинов",
        // множ, дательный падеж, кому? чему?
        manyDative: "магазинам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            total_orders: {
                title: "Кол-во заказов",
                dative: "кол-ву заказов",
                color: "red",
                type: "sales",
            },
            sum_price_orders: {
                title: "Сумма заказов",
                dative: "суммам заказов",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            total_sales: {
                title: "Кол-во продаж",
                dative: "кол-ву продаж",
                color: "blue",
                type: "sales",
            },
            sum_price_sales: {
                title: "Сумма продаж",
                dative: "суммам продажа",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            total_return_sales: {
                title: "Кол-во возвратов",
                dative: "кол-ву возвратов",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            sum_price_return_sales: {
                title: "Сумма возвратов",
                dative: "суммам возвратов",
                color: "fiolet",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks: {
                title: "Капитализация склада",
                dative: "капитализации складов",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            sum_stocks_cost_price: {
                title: "Себестоимость склада",
                dative: "себестоимости склада",
                color: "green",
                type: "money",
            },
            sum_comission_sales: {
                title: "Сумма комиссий",
                dative: "сумме комиссий",
                color: "brown",
                type: "money",
            },
            sum_revenue_sales: {
                title: "Выручка",
                dative: "выручке",
                type: "money",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    project: {
        // единств, именительный падеж, кто? что?
        one: "проект",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "проекта",
        // единств, дательный падеж, кому? чему?
        oneDative: "проекту",
        // единств, винительный падеж, кого? что?
        oneAccusative: "проект",
        // множ, именительный падеж, кто? что?
        many: "проекты",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "проектов",
        // множ, дательный падеж, кому? чему?
        manyDative: "проектам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            queries: {
                title: "Запросов",
                dative: "Запросам",
                id: "queries",
                color: "pink",
                // columnUnit: false,
            },
            products: {
                title: "Товаров",
                dative: "Товарам",
                id: "products",
                color: "pink",
                // columnUnit: false,
            },
            top1000: {
                title: "Запросов с позициями",
                dative: "Запросам с позициями",
                color: "green",
                columnWidth: 140,
            },
            // total_products_with_pos: {
            //     title: "Товаров с позициями",
            //     dative: "товарам с позициям",
            //     color: "yellow",
            //     columnWidth: 140,
            // },
            avg_position: {
                title: "Средняя позиция",
                dative: "cредней позиции",
                color: "blue",
                columnWidth: 140,
            },
            top4: {
                title: "ТОП 4",
                dative: "ТОПу 4",
                color: "lightgreen",
                columnWidth: 140,
            },
            top12: {
                title: "ТОП 12",
                dative: "ТОПу 12",
                color: "orange",
                columnWidth: 140,
            },
            top100: {
                title: "ТОП 100",
                dative: "ТОПу 100",
                color: "red",
                columnWidth: 140,
            },
        },
    },
    financialAnalysisWb: {
        // единств, именительный падеж, кто? что?
        one: "финансовый анализ",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "финансового анализа",
        // единств, дательный падеж, кому? чему?
        oneDative: "финансовому анализу",
        // единств, винительный падеж, кого? что?
        oneAccusative: "финансовый анализ",
        // множ, именительный падеж, кто? что?
        many: "финансовые анализы",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "финансовых анализов",
        // множ, дательный падеж, кому? чему?
        manyDative: "финансовым анализам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            sales_total: {
                title: "Продажи (руб.)",
                dative: "Продажам (руб.)",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            commission_total: {
                title: "Комиссия (руб.)",
                dative: "Комиссии (руб.)",
                color: "red",
                type: "money",
            },
            logistics_total: {
                title: "Логистика (руб.)",
                dative: "Логистике (руб.)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            revenue_total: {
                title: "Выручка (руб.)",
                dative: "Выручке (руб.)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            commission_total_percent: {
                title: "Итоговая комиссия с учётом логистики %",
                dative: "Итоговой комиссии %",
                color: "fiolet",
                type: "",
                columnWidth: 140,
            },
            cost_price_total: {
                title: "Себестоимость проданных товаров (руб.)",
                dative: "Себестоимости проданных товаров (руб.)",
                color: "blue",
                type: "money",
            },
            profit_total: {
                title: "Прибыль (руб.)",
                dative: "Прибыль (руб.)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            marginality_total_percent: {
                title: "Маржинальность (%)",
                dative: "Маржинальность (%)",
                color: "green",
                type: "",
            },
            sales_total_count: {
                title: "Продажи (шт.)",
                dative: "Продажам (шт.)",
                type: "sales",
                color: "brown",
            },
            returns_total_count: {
                title: "Возвраты (шт.)",
                dative: "Возвратам (шт.)",
                type: "sales",
                color: "blue",
            },
            non_purchases_total_count: {
                title: "Невыкупы (шт.)",
                dative: "Невыкупам (шт.)",
                type: "sales",
                color: "pink",
            },
            orders_total_count: {
                title: "Заказы (шт.)",
                dative: "Заказам (шт.)",
                type: "sales",
                color: "blue",
            },
            commission_ratio_percent: {
                title: "Доля комисии (%)",
                dative: "Доле комисии (%)",
                color: "brown",
                type: "",
            },
            logistics_ratio_percent: {
                title: "Доля логистики (%)",
                dative: "Доля логистики (%)",
                type: "",
                color: "red",
            },
            // on_the_way_total_count: {
            //     title: "В пути (шт.)",
            //     dative: "В пути (шт.)",
            //     type: "sales",
            //     color: "yellow",
            // },
            average_check: {
                title: "Средний чек (руб.)",
                dative: "Среднему чеку (руб.)",
                type: "money",
                color: "lightgreen",
            },
            average_revenue: {
                title: "Средняя выручка на товар (руб.)",
                dative: "Средней выручке на товар (руб.)",
                type: "money",
                color: "fiolet",
            },
            // purchase_percent: {
            //     title: "Процент выкупа (%)",
            //     dative: "Проценту выкупа (%)",
            //     type: "",
            //     color: "orange",
            // },
            average_profit: {
                title: "Средняя прибыль на товар (руб.)",
                dative: "Средней прибыли на товар (руб.)",
                type: "money",
                color: "red",
            },
        },
    },

    financialAnalysisOzon: {
        // единств, именительный падеж, кто? что?
        one: "финансовый анализ",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "финансового анализа",
        // единств, дательный падеж, кому? чему?
        oneDative: "финансовому анализу",
        // единств, винительный падеж, кого? что?
        oneAccusative: "финансовый анализ",
        // множ, именительный падеж, кто? что?
        many: "финансовые анализы",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "финансовых анализов",
        // множ, дательный падеж, кому? чему?
        manyDative: "финансовым анализам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            sales_total: {
                title: "Продажи (руб.)",
                dative: "Продажам (руб.)",
                color: "lightgreen",
                type: "money",
                columnWidth: 140,
            },
            /*
            commission_total: {
                title: "Комиссия (руб.)",
                dative: "Комиссии (руб.)",
                color: "red",
                type: "money",
            },
            */

            /*
            logistics_total: {
                title: "Логистика (руб.)",
                dative: "Логистике (руб.)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            store_total_count: {
                title: "Хранение (руб.)",
                dative: "Хранению (руб.)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            ads_total: {
                title: "Реклама (руб.)",
                dative: "Рекламe (руб.)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            */


            /*
            //!&:
            bonus_total: {
                title: "Бонус (руб.)",
                dative: "Бонус (руб.)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            compensation_total: {
                title: "Компенсации (руб.)",
                dative: "Компенсации (руб.)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            correction_total: {
                title: "Корректировки (руб.)",
                dative: "Корректировки (руб.)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            logistics_total: {
                title: "Логистика (руб.)",
                dative: "Логистике (руб.)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            ads_total: {
                title: "Реклама (руб.)",
                dative: "Рекламe (руб.)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            service_total: {
                title: "Услуги (руб.)",
                dative: "Услуги (руб.)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            finance_total: {
                title: "Фин.услуги (руб.)",
                dative: "Фин.услуги (руб.)",
                color: "brown",
                type: "money",
                columnWidth: 140,
            },
            store_total: {
                title: "Хранение (руб.)",
                dative: "Хранению (руб.)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            penalty_total: {
                title: "Штрафы (руб.)",
                dative: "Штрафы (руб.)",
                color: "red",
                type: "money",
                columnWidth: 140,
            },
            */
            //!&: 2024-09-20: новое упорядочение
            logistics_total: {
                //1
                title: "Логистика (руб.)",
                dative: "Логистике (руб.)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            store_total_count: {
                //2
                title: "Хранение (руб.)",
                dative: "Хранению (руб.)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            ads_total: {
                //3
                title: "Реклама (руб.)",
                dative: "Рекламe (руб.)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            service_total: {
                //4
                title: "Услуги (руб.)",
                dative: "Услуги (руб.)",
                color: "green",
                type: "money",
                columnWidth: 140,
            },
            finance_total: {
                //5
                title: "Фин.услуги (руб.)",
                dative: "Фин.услуги (руб.)",
                color: "brown",
                type: "money",
                columnWidth: 140,
            },
            penalty_total: {
                //6
                title: "Штрафы (руб.)",
                dative: "Штрафы (руб.)",
                color: "red",
                type: "money",
                columnWidth: 140,
            },
            compensation_total: {
                //7
                title: "Компенсации (руб.)",
                dative: "Компенсации (руб.)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            correction_total: {
                //8
                title: "Корректировки (руб.)",
                dative: "Корректировки (руб.)",
                color: "blue",
                type: "money",
                columnWidth: 140,
            },
            bonus_total: {
                //9
                title: "Бонус (руб.)",
                dative: "Бонус (руб.)",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },


                    /*
                    Логистика (руб.)
                    Хранение (руб.)
                    Реклама (руб.)
                    Услуги (руб.)
                    Фин.услуги (руб.)
                    Штрафы (руб.)
                    Компенсации (руб.)
                    Корректировки (руб.)
                    Бонус (руб.)
                    */


            commission_total_percent: {
                title: "Итоговая комиссия %",
                dative: "Итоговой комиссии %",
                color: "lightgreen",
                type: "",
                columnWidth: 140,
            },
            revenue_total: {
                title: "Выручка (руб.)",
                dative: "Выручке (руб.)",
                color: "orange",
                type: "money",
                columnWidth: 140,
            },
            cost_price_total: {
                title: "Себестоимость проданных товаров (руб.)",
                dative: "Себестоимости проданных товаров (руб.)",
                color: "blue",
                type: "money",
            },
            /*
            profit_total: {
                title: "Прибыль (руб.)",
                dative: "Прибыль (руб.)",
                color: "pink",
                type: "money",
                columnWidth: 140,
            },
            */
            marginality_total_percent: {
                title: "Маржинальность (%)",
                dative: "Маржинальность (%)",
                color: "green",
                type: "",
            },
            sales_total_count: {
                title: "Продажи (шт.)",
                dative: "Продажам (шт.)",
                type: "sales",
                color: "brown",
            },
            returns_total_count: {
                title: "Возвраты (шт.)",
                dative: "Возвратам (шт.)",
                type: "sales",
                color: "blue",
            },
            non_purchases_total_count: {
                title: "Невыкупы (шт.)",
                dative: "Невыкупам (шт.)",
                type: "sales",
                color: "pink",
            },
            orders_total_count: {
                title: "Заказы (шт.)",
                dative: "Заказам (шт.)",
                type: "sales",
                color: "blue",
            },
            commission_ratio_percent: {
                title: "Доля комисии (%)",
                dative: "Доле комисии (%)",
                color: "brown",
                type: "",
            },
            logistics_ratio_percent: {
                title: "Доля логистики (%)",
                dative: "Доля логистики (%)",
                type: "",
                color: "red",
            },
            ads_ratio_percent: {
                title: "Доля рекламных расходов (%)",
                dative: "Доле рекламных расходов (%)",
                type: "",
                color: "brown",
            },
            other_ratio_percent: {
                title: "Доля прочих расходов (%)",
                dative: "Доле прочих расходов (%)",
                type: "",
                color: "pink",
            },
            average_check: {
                title: "Средний чек (руб.)",
                dative: "Среднему чеку (руб.)",
                type: "money",
                color: "lightgreen",
            },
            average_revenue: {
                title: "Средняя выручка на товар (руб.)",
                dative: "Средней выручке на товар (руб.)",
                type: "money",
                color: "fiolet",
            },
            average_profit: {
                title: "Средняя прибыль на товар (руб.)",
                dative: "Средней прибыли на товар (руб.)",
                type: "money",
                color: "red",
            },
            other_ratio_total_count: {
                title: "Прочие расходы (руб.)",
                dative: "Прочие расходы (руб.)",
                type: "money",
                color: "green",
            },
        },
    },

    product: {
        one: "товар",
        oneGenitive: "товара",
        oneDative: "товару",
        // единств, винительный падеж, кого? что?
        oneAccusative: "товар",
        many: "товары",
        manyGenitive: "товаров",
        manyDative: "товарам",
        textProp: "title",
        idProp: "p_id",
        gender: "male",
        metrics: {
            sold: {
                title: "Продажи",
                dative: "продажам",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "Выручка",
                dative: "выручке",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },

            //! OZ <> wb
            price: {
                title: "Цена",
                dative: "цене",
                color: "blue",
                type: "money",
                ////mp: "ozon",
            },
            price_old: {
                title: "Старая цена",
                dative: "старой цене",
                type: "money",
                list: false,
                cell: false,
                table: true,
                ////mp: "ozon",
            },
            discount: {
                title: "Скидка",
                dative: "скидке",
                type: "percent",
                list: false,
                cell: false,
                table: true,
                mp: "ozon",
            },

            //! WB <> oz
            price_spp: {
                //title: "Базовая Цена",
                //dative: "Базовой цене",
                title: "Цена", //wb-no-spp
                dative: "цене",
                type: "money",
                color: "blue",
                mp: "wb",
            },

            /*
            price_discount_spp: {
                title: "СПП",
                dative: "СПП",
                type: "percent",
                color: "pink",
                mp: "wb",
            },
            */
            discount_wb: {
                title: "Cкидка",
                dative: "скидке",
                type: "percent",
                color: "pink",
                list: false,
                cell: false,
                table: true,
                mp: "wb",
            },

            remains: {
                title: "Текущий остаток",
                dative: "текущему остатку",
                color: "lightgreen",
            },
            reviews: {
                title: "Отзывы",
                dative: "отзывам",
                color: "fiolet",
            },
            rating: {
                title: "Рейтинг",
                dative: "рейтингу",
                color: "lightyellow",
            },
            categories: {
                title: "Кол-во категорий",
                dative: "кол-ву категорий",
                color: "darkblue",
            },

            //kwd
            keywords: {
                title: "Поисковые запросы",
                dative: "поисковым запросам",
                color: "blue",
                columnWidth: 140,
            },

            avg_sold: {
                title: "Сред. продаж в день",
                dative: "среднему продаж в день",
                type: "sales",
                color: "lightyellow",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "Сред. выручка в день",
                dative: "средней выручке в день",
                type: "money",
                color: "lightyellow",
                columnWidth: 140,
            },
            days: {
                title: "Дней в наличии",
                dative: "дням в наличии",
                id: "days",
                type: "days",
                color: "pink",
                columnUnit: false,
            },
            losses: {
                title: "Упущенная выручка",
                dative: "упущенной выручке",
                id: "losses",
                type: "money",
                color: "pink",
            },
        },
    },

    brand: {
        one: "бренд",
        oneGenitive: "бренда",
        oneDative: "бренду",
        // единств, винительный падеж, кого? что?
        oneAccusative: "бренд",
        many: "бренды",
        manyGenitive: "брендов",
        manyDative: "брендам",
        textProp: "brand",
        idProp: "b_id",
        gender: "male",
        metrics: {
            sold: {
                title: "Продажи",
                dative: "продажам",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "Выручка",
                dative: "выручке",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "Товаров",
                dative: "товарам",
                color: "blue",
            },
            products_with_sales: {
                title: "Товаров с продажами",
                dative: "товарам с продажами",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                ////title: "Выручка на товар",
                ////dative: "выручке на товар",
                title: `Средний чек`,
                dative: `среднему чеку`,

                color: "orange",
                type: "money",
            },
            sellers: {
                title: "Продавцов",
                dative: "продавцам",
                color: "green",
                columnWidth: 140,
            },
            sellers_with_sales: {
                title: "Продавцов с продажами",
                dative: "продавцам с продажами",
                percentOf: "sellers",
                color: "brown",
                columnWidth: 140,
            },
            categories: {
                title: "Кол-во категорий",
                dative: "кол-ву категорий",
                color: "darkblue",
            },
            avg_rating: {
                title: "Рейтинг товаров",
                dative: "рейтингу товаров",
                color: "lightyellow",
            },
            reviews: {
                title: "Отзывы",
                dative: "отзывам",
                color: "fiolet",
            },
            reviews_per_product: {
                title: "Отзывов на товар",
                dative: "отзывам на товар",
                color: "pink",
            },
            avg_sold: {
                title: "Сред. продаж в день",
                dative: "среднему продаж в день",
                type: "sales",
                color: "lightyellow",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "Сред. выручка в день",
                dative: "средней выручке в день",
                type: "money",
                color: "lightyellow",
                columnWidth: 140,
            },
        },
    },
    seller: {
        one: "продавец",
        oneGenitive: "продавца",
        oneDative: "продавцу",
        // единств, винительный падеж, кого? что?
        oneAccusative: "продавца",
        many: "продавцы",
        manyGenitive: "продавцов",
        manyDative: "продавцам",
        // множ, винительный падеж, кого? что?
        manyAccusative: "категории",
        textProp: "seller",
        idProp: "s_id",
        gender: "male",
        metrics: {
            sold: {
                title: "Продажи",
                dative: "продажам",
                color: "red",
                type: "sales",
            },
            revenue: {
                title: "Выручка",
                dative: "выручке",
                color: "yellow",
                type: "money",
                columnWidth: 140,
            },
            products: {
                title: "Товаров",
                dative: "товарам",
                color: "blue",
            },
            products_with_sales: {
                title: "Товаров с продажами",
                dative: "товарам с продажами",
                percentOf: "products",
                color: "lightgreen",
                columnWidth: 140,
            },
            revenue_per_product: {
                ////title: "Выручка на товар",
                ////dative: "выручке на товар",
                title: `Средний чек`,
                dative: `среднему чеку`,

                color: "orange",
                type: "money",
            },
            brands: {
                title: "Брендов",
                dative: "брендам",
                color: "green",
            },
            brands_with_sales: {
                title: "Брендов с продажами",
                dative: "брендам с продажами",
                percentOf: "brands",
                color: "brown",
                columnWidth: 140,
            },
            categories: {
                title: "Кол-во категорий",
                dative: "кол-ву категорий",
                color: "darkblue",
            },
            avg_rating: {
                title: "Рейтинг товаров",
                dative: "рейтингу товаров",
                color: "lightyellow",
            },
            reviews: {
                title: "Отзывы",
                dative: "отзывам",
                color: "fiolet",
            },
            reviews_per_product: {
                title: "Отзывов на товар",
                dative: "отзывам на товар",
                color: "pink",
            },
            avg_sold: {
                title: "Сред. продаж в день",
                dative: "среднему продаж в день",
                type: "sales",
                color: "lightyellow",
                columnWidth: 140,
            },
            avg_revenue: {
                title: "Сред. выручка в день",
                dative: "средней выручке в день",
                type: "money",
                color: "lightyellow",
                columnWidth: 140,
            },
        },
    },
    reviewManagementWb: {
        // единств, именительный падеж, кто? что?
        one: "магазин",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "магазина",
        // единств, дательный падеж, кому? чему?
        oneDative: "магазину",
        // единств, винительный падеж, кого? что?
        oneAccusative: "магазин",
        // множ, именительный падеж, кто? что?
        many: "магазины",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "магазинов",
        // множ, дательный падеж, кому? чему?
        manyDative: "магазинам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            avg_valuation: {
                title: "Средний балл",
                dative: "Средниму баллу",
                color: "red",
                // type: "sales",
                columnWidth: 140,
            },
            count_feedbacks: {
                title: "Всего отзывов",
                dative: "Всего отзывов",
                color: "yellow",
                type: "sales",
                columnWidth: 140,
            },
            count_per_day: {
                title: "Отзывов в день",
                dative: "Отзывов в день",
                color: "blue",
                type: "sales",
                columnWidth: 140,
            },
            count_unanswered: {
                title: "Отзывы без ответа",
                dative: "Отзывам без ответа",
                color: "lightgreen",
                type: "sales",
                columnWidth: 140,
            },
            count_need_approval: {
                title: "Требуют модерации",
                dative: "Требуют модерации",
                color: "brown",
                type: "sales",
                columnWidth: 140,
            },
            count_today_unanswered: {
                title: "Без ответа за сегодня",
                dative: "Без ответа за сегодня",
                color: "orange",
                type: "sales",
                columnWidth: 140,
            },
            count_scripts: {
                title: "Активные скрипты",
                dative: "Активным скриптам",
                color: "fiolet",
                type: "sales",
                columnWidth: 140,
            },
            count_answers: {
                title: "Отвечено скриптом",
                dative: "Отвечено скриптом",
                color: "pink",
                type: "sales",
                columnWidth: 140,
            },
        },
    },
    repricerWb: {
        // единств, именительный падеж, кто? что?
        one: "магазин",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "магазина",
        // единств, дательный падеж, кому? чему?
        oneDative: "магазину",
        // единств, винительный падеж, кого? что?
        oneAccusative: "магазин",
        // множ, именительный падеж, кто? что?
        many: "магазины",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "магазинов",
        // множ, дательный падеж, кому? чему?
        manyDative: "магазинам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            totalProduct: {
                title: "Всего товаров",
                dative: "Всего товарам",
                color: "red",
                type: "sales",
                columnWidth: 140,
            },
            totalScriptActive: {
                title: "Активных скриптов",
                dative: "Активным скриптам",
                color: "yellow",
                type: "sales",
                columnWidth: 140,
            },
            totalProductActive: {
                title: "Товаров в управлении",
                dative: "Товарам в управлении",
                color: "blue",
                type: "sales",
                columnWidth: 140,
            },
        },
    },
    repricerOzon: {
        // единств, именительный падеж, кто? что?
        one: "магазин",
        // единств, родительный падеж, кого? чего?
        oneGenitive: "магазина",
        // единств, дательный падеж, кому? чему?
        oneDative: "магазину",
        // единств, винительный падеж, кого? что?
        oneAccusative: "магазин",
        // множ, именительный падеж, кто? что?
        many: "магазины",
        // множ, родительный падеж, кого? чего?
        manyGenitive: "магазинов",
        // множ, дательный падеж, кому? чему?
        manyDative: "магазинам",
        // поле сущности, в котором содержится текст название
        textProp: "c_name_path",
        // поле сщуности, в котором содержится идентификатор
        idProp: "c_id",
        // род: female, male, neuter
        gender: "male",
        metrics: {
            totalProduct: {
                title: "Всего товаров",
                dative: "Всего товарам",
                color: "red",
                type: "sales",
                columnWidth: 140,
            },
            totalScriptActive: {
                title: "Активных скриптов",
                dative: "Активным скриптам",
                color: "yellow",
                type: "sales",
                columnWidth: 140,
            },
            totalProductActive: {
                title: "Товаров в управлении",
                dative: "Товарам в управлении",
                color: "blue",
                type: "sales",
                columnWidth: 140,
            },
        },
    },
};
export const MARKETPLACES = {
    wb: "Wildberries",
    ozon: "OZON",
};

export function getEntitiesList(
    text = "many", // manyGenitive, manyDative
    textCase = "capitalize" // lower, upper
) {
    const result = {
        category: ENTITIES.category,
        product: ENTITIES.product,
        brand: ENTITIES.brand,
        seller: ENTITIES.seller,
    };

    const textCaseFns = {
        capitalize: (v) => v.charAt(0).toUpperCase() + v.slice(1),
        lower: (v) => v.toLowerCase(),
        upper: (v) => v.toUpperCase(),
    };
    Object.keys(result).forEach((key) => {
        if (textCase === null) {
            result[key] = result[key][text];
            return;
        }
        result[key] = textCaseFns[textCase](result[key][text]);
    });
    return result;
}

export function getMonitorListM() {
    ////text = "many", // manyGenitive, manyDative
    ////textCase = "capitalize" // lower, upper
    const result = {
        productPrice: "Товары : Цена",
        productDiscount: "Товары : Скидка",
        productReview: "Товары : Кол-во отзывов",
        productMaxQuantity: "Товары : Остатки",
        productPhotoCount: "Товары : Кол-во картинок",
        productCategory: "Товары : Кол-во категорий",
        productKeyword: "Товары : Кол-во запросов",
        productNewName: "Товары : Название",

        brandNewProduct: "Бренды : Новые товары",
        brandNewSeller: "Бренды : Новые продавцы",

        sellerNewProduct: "Продавцы : Новые товары",
        sellerNewBrand: "Продавцы : Новые бренды",

        categoryNewProduct: "Категории : Новые товары",
        categoryNewBrand: "Категории : Новые бренды",
        categoryNewSeller: "Категории : Новые продавцы",
    };

    /* @V:
            // const textCaseFns = {
            //     capitalize: (v) => v.charAt(0).toUpperCase() + v.slice(1),
            //     lower: (v) => v.toLowerCase(),
            //     upper: (v) => v.toUpperCase(),
            // };
            // Object.keys(result).forEach((key) => {
            //     if (textCase === null) {
            //         result[key] = result[key][text];
            //         return;
            //     }
            //     result[key] = textCaseFns[textCase](result[key][text]);
            // });
            */

    return result;
}

/*
export function getMonitorPriceVar2() {
    const result = {
        productPriceVar: "Любое отклонение",
        productPriceVarPercent: "Отклонение в %",
        productPriceVarRub: "Отклонение в руб.",
    };
    return result;
}
*/

export function getRrcMonitor() {
    const result = {
        productPriceVar: "Товары : Любое отклонение",
        productPriceVarPercent: "Товары : Отклонение в %",
        productPriceVarRub: "Товары : Отклонение в руб.",

        // productPriceVar: "Товары : Отклонение от РРЦ",
        // productPriceVarVal: "Товары : Отклонение от РРЦ + величина [%]",
        //productPriceVarUp: "Товары : Отклонение от РРЦ вверх + величина",
        //productPriceVarDown: "Товары : Отклонение от РРЦ вниз + величина",
    };
    return result;
}
export function getRrcMonitorArray() {
    const obj = getRrcMonitor();
    return _convertToArray(obj);
}

export function getRrcSchedule() {
    const result = {
        onDemand: "По требованию",
        eachDay: "Каждый день",
        eachDay3: "3 раза в день",
        eachHour: "Каждый час",
    };
    return result;
}
export function getRrcScheduleArray() {
    const obj = getRrcSchedule();
    return _convertToArray(obj);
}

export function getRrcPriceType(mp) {
    let result = {};
    if (mp == "wb") {
        result = {
            price: "Цена без WB кошелька",
            card_price: "Цена с WB кошельком",
        };
    } else {
        result = {
            price: "Цена без Ozon Карты",
            card_price: "Цена с Ozon Картой",
        };
    }
    return result;
}
export function getRrcPriceTypeArray(mp) {
    const obj = getRrcPriceType(mp);
    return _convertToArray(obj);
}

export function get_KeywordAnalyzeType_Type() {
    const result = {
        std: "Стандартный",
        live: "Live поиск",
        ////liveWithParam:  "Live поиск с фильтрами",
        url: "Live поиск с фильтрами",
    };
    return result;
}
export function get_KeywordAnalyzeType_Array() {
    const obj = get_KeywordAnalyzeType_Type();
    return _convertToArray(obj);
}

function _convertToArray(obj) {
    return Object.keys(obj).map((key) => {
        return {
            title: obj[key],
            id: key,
        };
    });
}

export function getEntitiesListArray(text = "many", textCase = "capitalize") {
    const obj = getEntitiesList(text, textCase);
    return _convertToArray(obj);
}

export function getMonitorListMArray() {
    //text = "many", textCase = "capitalize") {
    const obj = getMonitorListM(); //text, textCase);
    return _convertToArray(obj);
}

export function getMarketplacesList() {
    return MARKETPLACES;
}

export function getMarketplacesListArray() {
    const obj = getMarketplacesList();
    return _convertToArray(obj);
}

// [1, 2, 5] для плюрализации
export function getEntityPluralVariants(entity, caseOne) {
    const variants = ENTITIES[entity];
    return [variants[caseOne], variants.oneGenitive, variants.manyGenitive];
}

// Прюрализация сущности
export function pluralEntity(
    entity,
    number,
    format = "{v} {entity}",
    caseOne = "one"
) {
    const pluralFormats = getEntityPluralVariants(entity, caseOne).map(
        (variant) => format.replace("{entity}", variant)
    );
    return plural(number, pluralFormats);
}

export function gender(entity, [male, female, neuter]) {
    const gender = ENTITIES[entity].gender;
    if (gender === "male") {
        return male;
    }
    if (gender === "female") {
        return female;
    }
    if (gender === "neuter") {
        return neuter;
    }
}

export function getEntityCellsConfig(entity) {
    const config = ENTITIES[entity];

    const result = {};
    Object.keys(config.metrics).forEach((key) => {
        const metric = config.metrics[key];
        if (metric.cell === false) {
            return;
        }
        const units = {
            money: "руб",
            sales: "шт",
            days: "дн",
            default: null,
        };
        result[key] = {
            id: key,
            title: metric.title,
            color: metric.color,
            percentOf: metric.percentOf,
            unit: units[metric.type] || units.default,
        };
    });
    return result;
}

export function getEntityTableColumns(
    entity,
    startPosition = 1,
    preferred = null
) {
    const config = ENTITIES[entity];

    const result = [];
    let currentPosition = startPosition;

    const addColumn = (key) => {
        const metric = config.metrics[key];
        if (metric.table === false) {
            return;
        }
        const defaults = {
            money: {
                type: "money",
            },
            sales: {
                title:
                    `${metric.title}` +
                    (metric.columnUnit === false ? "" : " (шт)"),
            },
            days: {
                title:
                    `${metric.title}` +
                    (metric.columnUnit === false ? "" : " (дн)"),
            },
            percent: {
                type: "percent",
            },
            default: {},
        };
        result.push({
            name: key,
            title: metric.title,
            show: true,
            position: currentPosition++,
            width: metric.columnWidth || 100,
            filter: "numberRange",
            type: "number",
            ...(defaults[metric.type] || defaults.default),
        });
    };

    let preferredArray = [];
    if (preferred) {
        preferredArray = Array.isArray(preferred) ? preferred : [preferred];
    }
    preferredArray.forEach(addColumn);

    Object.keys(config.metrics).forEach((key) => {
        if (preferredArray.includes(key)) {
            return;
        }
        addColumn(key);
    });
    return result;
}

export function getEntityMetricsList(entity, text = "title", mp = null) {
    const config = ENTITIES[entity];

    //console.log('1308: mp=', mp)

    const result = {};
    Object.keys(config.metrics).forEach((key) => {
        let metric = config.metrics[key];
        if (metric.list === false) {
            return;
        }

        //&:2024-02-12
        if (
            mp !== null &&
            (metric.mp === "wb" || metric.mp === "ozon") &&
            metric.mp !== mp
        ) {
            return;
        }
        //подшаманим названия полей цен для меню
        if (mp === "wb") {
            if (key === "price") metric[text] = "Цена с WB кошельком";
            ////else if (key==='price_discount_spp') metric[text] = "СПП"
            else if (key === "price_spp") metric[text] = "Цена";
            else if (key === "discount_wb") metric[text] = "Скидка";
        } else {
            if (key === "price") metric[text] = "Цена";
        }

        result[key] = metric[text];
    });
    return result;
}

export function getEntityMetricsListArray(entity, mp = null) {
    //console.log('1331: mp=', mp)

    let text = "title";
    const obj = getEntityMetricsList(entity, text, mp);
    return _convertToArray(obj);
}
