import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async globalSearch(context, { mp = [], entity = [], query = "" }) {
            const { data } = await Vue.api.get("/globalSearch", {
                params: {
                    mp,
                    entity,
                    query,
                },
            });
            return data;
        },

        /* DEPRECATED
        // async getWarehouses (context, { mp, query = null, ids = null }) {
        // const { data } = await Vue.api.get('/getWarehouses', {
        //     params: {
        //     mp,
        //     query,
        //     ids
        //     }
        // })
        //     return data
        // },
        */


        async getCalendarDates(context, { mp }) {
            const { data } = await Vue.api.get("/getCalendarDates", {
                params: {
                    mp,
                },
            });

            ////console.log('store/getCalendarDates.37: data=',data)

            return data;
        },
    },
};
