import Vue from "vue";
import { getMarketplacesListArray } from "@/utils/enums";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import { filtersToObject, jsonToFormData } from "@/utils/newModule";

const select_marketplace_items = [...getMarketplacesListArray()];

export default {
    namespaced: true,
    state: {
        hasTokens: false,
        hasValidTokens: false,
        hasInitialReadyTokens: true,
        loading: false,
        loaded: false,
        tokens: [],
        selectedTokens:
            JSON.parse(localStorage.getItem("selectedTokens")) || [],
        dates: [],
        calendar: null,
        calendarBoundaries: [],
        marketplace:
            JSON.parse(localStorage.getItem("mp")) ||
            select_marketplace_items[0],
    },
    mutations: {
        SET_TOKENS(state, data) {
            state.tokens = data;
        },
        SET_HAS_TOKENS(state, data) {
            state.hasTokens = data;
        },
        SET_HAS_VALID_TOKENS(state, data) {
            state.hasValidTokens = data;
        },
        SET_LOADING(state, data) {
            state.loading = data;
            if (!data) {
                state.loaded = true;
            }
        },
        SET_HAS_INITIAL_READY_TOKENS(state, data) {
            state.hasInitialReadyTokens = data;
        },
        SET_CALENDAR(state, data) {
            state.calendar = data;
        },
        SET_DATES(state, data) {
            state.dates = data;
        },
        SET_CALENDAR_BOUNDARIES(state, data) {
            state.calendarBoundaries = data;
        },
        SET_SELECTED_TOKENS(state, data) {
            state.selectedTokens = data;
        },
        SET_MARKETPLACE(state, data) {
            state.marketplace = data;
        },
    },
    actions: {
        async setHasTokens({ dispatch }) {
            await dispatch("getTokens", {});
        },
        setMarketplace({ commit }, data) {
            commit("SET_MARKETPLACE", data);
        },
        setSelectedTokens({ commit }, data) {
            localStorage.setItem("selectedTokens", JSON.stringify(data));
            commit("SET_SELECTED_TOKENS", data);
        },
        setCalendar({ commit, state }) {
            if (state.calendar === null) {
                if (!state.calendarBoundaries) {
                    commit("SET_CALENDAR", null);
                    return;
                }
                const lastAvailableDate =
                    state.calendarBoundaries?.[1] || today();
                const monthRange = dateRangeFromDate(lastAvailableDate, 30);
                // const queryRange = dateRangeUnsafe(this.$route.query?.date, this.$route.query?.date2)
                commit(
                    "SET_CALENDAR",
                    dateRangeBoundaries(
                        [monthRange[0], monthRange[1]],
                        state.calendarBoundaries
                    )
                );
            }
        },
        updateCalendar({ commit }, data) {
            commit("SET_CALENDAR", data);
        },
        setDates({ commit }, data) {
            commit("SET_DATES", data);
        },
        setCalendarBoundaries({ commit, state }) {
            if (state.calendarBoundaries.length < 2) {
                const calendarBoundaries = ["2022-03-26", today()];
                commit("SET_CALENDAR_BOUNDARIES", calendarBoundaries);
            }
        },
        /**
         * Загружает токены пользователя и задает state-свойства доступности
         */
        async getTokens({ commit, dispatch, state }) {
            commit("SET_LOADING", true);
            let params = {
                take: "10000",
                "sort[0][field]": "id",
                "sort[0][dir]": "asc",
            };

            try {
                const { data } = await Vue.api.post(
                    "/service/seller/get_user_keys",
                    jsonToFormData(params)
                );
                const tokens = data?.data;
                // tokens.push({
                //     id: 1245,
                //     user_id: 3572,
                //     datasource: "wb",
                //     apikey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2Nlc3NJRCI6IjI1YjdjODU2LTA0NjYtNDkyMS04ZmNkLWM4NjgzM2I3MTJkNCJ9.FgdmfylKOTGPOQ7c9mb8adJtsOcWQlQ1zkOeTyAXZUc",
                //     last_update: "2024-09-03 14:14:14",
                //     name: "test10978",
                //     status: 60,
                //     initial: 1,
                //     client_id: null,
                //     last_synced: "2023-12-07 12:17:49",
                //     initial_ready: 1,
                //     state: 10,
                //     created_at: "2023-06-06 18:12:36",
                //     type: 0,
                // });
                commit("SET_TOKENS", tokens);
                const filteredSelectedTokens = state.selectedTokens.filter(
                    (token) => {
                        return tokens.some((item) => item.id === token.id);
                    }
                );
                dispatch("setSelectedTokens", filteredSelectedTokens);
                const hasTokens = !!data?.total;
                commit("SET_HAS_TOKENS", hasTokens);
                const hasValidTokens = data?.data.some((item) => {
                    return ![10, 20].includes(item.status);
                });
                commit("SET_HAS_VALID_TOKENS", hasValidTokens);
                const hasInitialReadyTokens = data?.data.some((item) => {
                    return item.initial_ready === 1;
                });
                commit("SET_HAS_INITIAL_READY_TOKENS", hasInitialReadyTokens);
                commit("SET_LOADING", false);
                return data;
            } catch (e) {
                console.error(e);
            }
            return {};
        },
        // Выборка по токенам пользователя
        async queryTokens(
            { dispatch },
            { take = 20, skip = 0, sort = "id", sort_dir = "asc" }
        ) {
            let params = {
                take,
                skip,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            ////console.log(take, skip, sort, sort_dir);
            try {
                const { data } = await Vue.api.post(
                    "/service/seller/get_user_keys",
                    jsonToFormData(params)
                );
                await dispatch("getTokens");
                return data;
            } catch (e) {
                console.error(e);
            }
            return {};
        },
        async createToken(
            { dispatch },
            { datasource, apikey, name, client_id }
        ) {
            const { data } = await Vue.api.post(
                "/service/seller/create_user_keys",
                {
                    datasource,
                    apikey,
                    name,
                    client_id,
                }
            );

            ////console.log("created token");

            await dispatch("getTokens");
            return data;
        },
        async updateToken(ctx, { id, name, datasource, apikey, user_id }) {
            const { data } = await Vue.api.post(
                "/service/seller/update_user_keys",
                {
                    id,
                    name,
                    datasource,
                    apikey,
                    user_id,
                }
            );
            return data;
        },
        async removeToken({ dispatch }, { id }) {
            const { data } = await Vue.api.post(
                "/service/seller/destroy_user_keys",
                {
                    id,
                }
            );
            await dispatch("getTokens");
            return data;
        },
        async updateWbProducts(ctx, { item, newCost }) {
            item.unmapped = newCost;
            const { data } = await Vue.api.post(
                "/service/seller/wb/update_products",
                {
                    ...item,
                }
            );
            return data;
        },
        async updateOzProducts(ctx, { item, newCost }) {
            item.unmapped = newCost;
            const { data } = await Vue.api.post(
                "/service/seller/oz/update_products",
                {
                    ...item,
                }
            );
            return data;
        },
        async getWbHighlights(
            context,
            { page_size, page = 1, date, date2, tokens }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_highlights",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzHighlights(
            context,
            { page_size, page = 1, date, date2, tokens }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_highlights",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbHighlightsByDays(
            context,
            { page_size = 100, page = 1, date, date2, tokens }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_highlights_by_days",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzHighlightsByDays(
            context,
            { page_size = 100, page = 1, date, date2, tokens }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_highlights_by_days",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbOrders(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "lastChangeDate",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_orders",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzOrders(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "lastChangeDate",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_orders",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzReturns(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "accepted_from_customer_moment",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "accepted_from_customer_moment",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "accepted_from_customer_moment",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_returns",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbSales(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "lastChangeDate",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                types = [],
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                "filter[filters][3][field]": "sale_prefix",
                "filter[filters][3][operator]": "=",
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            if (types.length > 0) params["filter[filters][3][value][]"] = types;

            const { data } = await Vue.api.post(
                "/service/seller/wb/get_sales",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzSales(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "date_add",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                types = [],
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                "filter[filters][3][field]": "status",
                "filter[filters][3][operator]": "=",
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            if (types.length > 0) params["filter[filters][3][value][]"] = types;

            const { data } = await Vue.api.post(
                "/service/seller/oz/get_sales",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbProducts(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "date_add",
                sort_dir = "asc",
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_products",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzProducts(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "date_add",
                sort_dir = "asc",
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_products",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbStorage(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "Quantity",
                sort_dir = "desc",
                date,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "snapshotDate",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "uk_id",
                "filter[filters][1][operator]": "=",
                "filter[filters][1][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_stocks",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzStorage(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "quantity",
                sort_dir = "desc",
                date,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "snapshot_date",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "uk_id",
                "filter[filters][1][operator]": "=",
                "filter[filters][1][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_stocks",
                jsonToFormData(params)
            );
            return data;
        },
        async importWbProducts(context, { file }) {
            let form_data = new FormData();
            form_data.append("files", file);
            const { data } = await Vue.api.post(
                "/service/seller/wb/import_products",
                form_data
            );
            return data;
        },
        async importWbProductsState(context, { hash }) {
            let params = {
                hash: hash,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/import_products_state",
                jsonToFormData(params)
            );
            return data;
        },
        async importOzProducts(context, { file }) {
            let form_data = new FormData();
            form_data.append("files", file);
            const { data } = await Vue.api.post(
                "/service/seller/oz/import_products",
                form_data
            );
            return data;
        },
        async importOzProductsState(context, { hash }) {
            let params = {
                hash: hash,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/import_products_state",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbStores(
            context,
            {
                take = "1000",
                skip = "0",
                page = "1",
                pageSize = "1000",
                datasource = "wb",
            }
        ) {
            let params = {
                datasource: datasource,
                take: take,
                skip: skip,
                page: page,
                pageSize: pageSize,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_stores",
                jsonToFormData(params)
            );
            return data;
        },
        //! &: 20204-06-27: oz: store -> cluster
        async getOzStores(
            context,
            { take = "1000", skip = "0", page = "1", pageSize = "1000" }
        ) {
            let params = {
                take: take,
                skip: skip,
                page: page,
                pageSize: pageSize,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_stores",
                jsonToFormData(params)
            );
            return data;
        },
        async createWbCalcDeliveryRequest(context, { hash, parameters }) {
            const stores = [];
            parameters.stores.forEach((item) => {
                item.forEach((el) => {
                    stores.push(el);
                });
            });
            parameters = `{"uk_id":[${parameters.uk_id}],"store":[${stores}],"date_delivery":"${parameters.date_delivery}","date_start":"${parameters.date_start}","date_end":"${parameters.date_end}","count_days":${parameters.count_days},"ratio":${parameters.ratio}}`;
            let params = {
                hash: hash,
                name: hash,
                params: parameters,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/create_calc_delivery_request",
                params
            );
            return data;
        },
        async createOzCalcDeliveryRequest(context, { hash, parameters }) {
            ////console.log('775: parameters.cluster=',parameters.clusters)

            const clusters = [];
            parameters.clusters.forEach((id) => {
                clusters.push(id);
            });
            parameters = `{"uk_id":[${parameters.uk_id}],"clusters":[${clusters}],"date_delivery":"${parameters.date_delivery}","date_start":"${parameters.date_start}","date_end":"${parameters.date_end}","count_days":${parameters.count_days},"ratio":${parameters.ratio}}`;
            let params = {
                hash: hash,
                name: hash,
                params: parameters,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/create_calc_delivery_request",
                params
            );
            return data;
        },
        async getWbCalcDeliveryProgress(context, { hash }) {
            let params = {
                request_hash: hash,
                take: "1",
                skip: "0",
                page: "1",
                pageSize: "1",
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_calc_delivery_progress",
                params
            );
            return data;
        },
        async getOzCalcDeliveryProgress(context, { hash }) {
            let params = {
                request_hash: hash,
                take: "1",
                skip: "0",
                page: "1",
                pageSize: "1",
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_calc_delivery_progress",
                params
            );
            return data;
        },
        async getWbCalcDeliveryProducts(
            context,
            {
                page_size = 20,
                page = 1,
                hash,
                sort = "delivery_quantity",
                sort_dir = "desc",
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                request_hash: hash,
                take: page_size,
                skip: (page - 1) * page_size,
                page: page,
                ...otherFilters,
                pageSize: page_size,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_calc_delivery_products",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzCalcDeliveryProducts(
            context,
            {
                page_size = 20,
                page = 1,
                hash,
                sort = "delivery_quantity",
                sort_dir = "desc",
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                request_hash: hash,
                take: page_size,
                skip: (page - 1) * page_size,
                page: page,
                ...otherFilters,
                pageSize: page_size,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_calc_delivery_products",
                jsonToFormData(params)
            );
            return data;
        },
        async updateWbCalcDeliveryProducts(context, { item }) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/update_calc_delivery_products",
                { ...item }
            );
            return data;
        },
        async updateOzCalcDeliveryProducts(context, { item }) {
            const { data } = await Vue.api.post(
                "/service/seller/oz/update_calc_delivery_products",
                { ...item }
            );
            return data;
        },
        async getWbABC(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "price_group_id",
                sort_dir = "asc",
                date,
                date2,
                tokens,
                categories,
                brands,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                "filter[filters][3][field]": "category",
                "filter[filters][3][operator]": "=",
                "filter[filters][4][field]": "brand",
                "filter[filters][4][operator]": "=",
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };

            if (categories?.length > 0)
                params["filter[filters][3][value][]"] = categories;
            if (brands?.length > 0)
                params["filter[filters][4][value][]"] = brands;

            const { data } = await Vue.api.post(
                "/service/seller/wb/get_abc_analysis",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzABC(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "price_group_id",
                sort_dir = "asc",
                date,
                date2,
                tokens,
                //&: categories,
                // brands,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,

                "filter[filters][3][field]": "category_name",
                "filter[filters][3][operator]": "=",

                // "filter[filters][4][field]":	"brand",
                // "filter[filters][4][operator]":	"=",

                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };

            /* &:
            if (categories?.length > 0)
                params["filter[filters][3][value][]"] = categories;
            */

            // if (brands.length > 0) params["filter[filters][4][value][]"] = brands

            const { data } = await Vue.api.post(
                "/service/seller/oz/get_abc_analysis",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbABCBrands() {
            let params = {
                take: "1000",
                skip: "0",
                page: "1",
                pageSize: "1000",
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_abc_analysis_products_brands",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzABCBrands() {
            let params = {
                take: "1000",
                skip: "0",
                page: "1",
                pageSize: "1000",
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_abc_analysis_products_brands",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbABCCategories() {
            let params = {
                take: "1000",
                skip: "0",
                page: "1",
                pageSize: "1000",
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_abc_analysis_products_categories",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzABCCategories() {
            let params = {
                take: "1000",
                skip: "0",
                page: "1",
                pageSize: "1000",
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_abc_analysis_products_categories",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbCompare(
            context,
            {
                page_size = 100,
                page = 1,
                sort = "value1",
                sort_dir = "asc",
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
            }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "docDate1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "docDate2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "docDate2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_sales_compare",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzCompare(
            context,
            {
                page_size = 100,
                page = 1,
                sort = "value1",
                sort_dir = "asc",
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
            }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "created_at1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "created_at2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "created_at2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_sales_compare",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbCompareBySKU(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "date_add",
                sort_dir = "desc",
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "docDate1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "docDate2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "docDate2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_sales_compare_by_sku",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzCompareBySKU(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "date_add",
                sort_dir = "desc",
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "created_at1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "created_at2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "created_at2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_sales_compare_by_sku",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbCompareByDays(
            context,
            {
                page_size = 100,
                page = 1,
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
            }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "docDate1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "docDate2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "docDate2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/wb/get_sales_compare_by_days",
                jsonToFormData(params)
            );
            return data;
        },
        async getOzCompareByDays(
            context,
            {
                page_size = 100,
                page = 1,
                date1_1,
                date1_2,
                date2_1,
                date2_2,
                tokens,
            }
        ) {
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at1",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date1_1,
                "filter[filters][1][field]": "created_at1",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date1_2,
                "filter[filters][2][field]": "created_at2",
                "filter[filters][2][operator]": "gt",
                "filter[filters][2][value]": date2_1,
                "filter[filters][3][field]": "created_at2",
                "filter[filters][3][operator]": "lt",
                "filter[filters][3][value]": date2_2,
                "filter[filters][4][field]": "uk_id",
                "filter[filters][4][operator]": "=",
                "filter[filters][4][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/oz/get_sales_compare_by_days",
                jsonToFormData(params)
            );
            return data;
        },
        async getWbSalesByRegions(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "total_count",
                sort_dir = "desc",
                date,
                date2,
                group,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "docDate",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "docDate",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/wb/get_sales_by_regions?group=${group}`,
                jsonToFormData(params)
            );
            return data;
        },
        async getOzSalesByRegions(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "total_count",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "created_at",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "created_at",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_sales_by_regions?group=regions`,
                jsonToFormData(params)
            );
            return data;
        },
        async getWbFinancialAnalysisTotal(context, { date, date2, tokens }) {
            let params = {
                take: 1000,
                skip: 0,
                page: 1,
                pageSize: 1000,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                `/service/seller/wb/get_financial_analysis_total`,
                jsonToFormData(params)
            );
            return data;
        },
        async getWbFinancialAnalysis(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "cl_for_pay",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/wb/get_financial_analysis`,
                jsonToFormData(params)
            );
            return data;
        },
        async getWbFinancialAnalysisBySku(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "quantity",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/wb/get_financial_analysis_by_sku`,
                jsonToFormData(params)
            );
            return data;
        },
        async getWbFinancialAnalysisBorderDates(context, { tokens }) {
            let params = {
                "filter[logic]": "and",
                "filter[filters][0][field]": "uk_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                `/service/seller/wb/get_financial_analysis_border_dates`,
                jsonToFormData(params)
            );
            return data;
        },
        async getOzFinancialAnalysisTotal(context, { date, date2, tokens }) {
            let params = {
                take: 1000,
                skip: 0,
                page: 1,
                pageSize: 1000,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_financial_analysis_total`,
                jsonToFormData(params)
            );
            return data;
        },
        async getOzFinancialAnalysis(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "cl_for_pay",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_financial_analysis`,
                jsonToFormData(params)
            );

            ////console.log('C.1560: data=', data)

            return data;
        },
        async getOzFinancialAnalysisBySku(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "quantity",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_financial_analysis_by_sku`,
                jsonToFormData(params)
            );
            return data;
        },
        async getOzFinancialAnalysisBorderDates(context, { tokens }) {
            let params = {
                "filter[logic]": "and",
                "filter[filters][0][field]": "uk_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_financial_analysis_border_dates`,
                jsonToFormData(params)
            );
            return data;
        },

        async getOzFinancialAnalysisByExpenses(
            context,
            {
                page_size = 20,
                page = 1,
                sort = "quantity",
                sort_dir = "desc",
                date,
                date2,
                tokens,
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);

            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "cl_operation_dt",
                "filter[filters][0][operator]": "gt",
                "filter[filters][0][value]": date,
                "filter[filters][1][field]": "cl_operation_dt",
                "filter[filters][1][operator]": "lt",
                "filter[filters][1][value]": date2,
                "filter[filters][2][field]": "uk_id",
                "filter[filters][2][operator]": "=",
                "filter[filters][2][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/oz/get_financial_analysis_by_expenses`,
                jsonToFormData(params)
            );

            ////console.log('Conn.1657: data=', data)

            return data;
        },
    },
};
