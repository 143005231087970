import router from "../router";

export default {
    install(Vue) {
        const VK_RTRG = process.env.VK_RTRG || "VK-RTRG-1621744-c2CR";
        const vkRetargetingPromise = new Promise((resolve) => {
            var head =
                document.head || document.getElementsByTagName("head")[0];
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.async = true;
            script.src = "https://vk.com/js/api/openapi.js?169";

            head.appendChild(script);

            script.onload = () => {
                window.VK.Retargeting.Init(VK_RTRG);
                resolve(window.VK);
            };
        });
        router.afterEach(async () => {
            const VK = await vkRetargetingPromise;
            // VK RTRG doesn't work well with SPAs
            // This is a workaround - we clean page metadata cache to send Hit event more than one time a page
            VK._pData = undefined;
            VK.Retargeting.Hit();
        });
        const telemetry = {
            reachGoal(goalId) {
                return new Promise((resolve) => {
                    try {
                        let resolved = false;
                        Vue.$metrika.reachGoal(goalId, {}, () => {
                            if (!resolved) {
                                resolved = true;
                                resolve();
                            }
                        });
                        setTimeout(() => {
                            if (!resolved) {
                                resolved = true;
                                resolve();
                            }
                        }, 3000);
                        if (process.env.NODE_ENV !== "production") {
                            resolved = true;
                            resolve();
                        }
                    } catch (e) {
                        console.error(e);
                    }
                });
            },
        };
        Vue.telemetry = telemetry;
        Vue.prototype.$telemetry = telemetry;
    },
};
