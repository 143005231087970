import Access from "@common/access";
import store from "@/store";

export default {
    install(Vue) {
        const access = new Access({
            getUser: () => store.state.user.user,
            getSubscriptions: () =>
                store.state.billing.applyingSubscriptions || [],
            getLimitsOwner: () => store.state.billing.limitsOwner,
            throwFunction: (accessError) => {
                switch (accessError.type) {
                    case "authorizedFunctional":
                        Vue.modal.open(
                            () =>
                                import(
                                    "@/components/modals/access/ModalRegister"
                                ),
                            { message: accessError.text, exclusive: true }
                        );
                        break;
                    case "noSubscription":
                        Vue.modal.open(
                            () =>
                                import(
                                    "@/components/modals/access/ModalNoSubscription"
                                ),
                            { message: accessError.text, exclusive: true }
                        );
                        break;
                    case "weakPlan":
                        Vue.modal.open(
                            () =>
                                import(
                                    "@/components/modals/access/ModalWeakPlan"
                                ),
                            { message: accessError.text, exclusive: true }
                        );
                        break;
                }
                throw new Error("AccessDenied");
            },
        });
        Vue.access = access;
        Vue.prototype.$access = access;
    },
};
