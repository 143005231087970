var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": "Спасибо за регистрацию в SalesFinder",
      "width": "1000",
      "title-position": "center",
      "advanced": "",
      "wide": false,
      "rightDark": true
    },
    scopedSlots: _vm._u([{
      key: "right",
      fn: function () {
        return [_c('div', {
          staticClass: "modal-right"
        }, [_c('span', {
          staticClass: "modal-right__title"
        }, [_vm._v(" Добро пожаловать в SalesFinder ")]), _c('img', {
          staticClass: "modal-right__img",
          attrs: {
            "src": require('@/assets/images/welcome/welcome.png'),
            "alt": "welcome"
          }
        })])];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "content"
  }, [_c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Мы рады видеть вас в числе пользователей нашего сервиса. Вот три первых шага, которые помогут вам начать: ")]), _c('a', {
    staticClass: "welcome-block",
    attrs: {
      "href": "https://help.salesfinder.ru",
      "target": "_blank"
    }
  }, [_c('svg', {
    staticClass: "welcome-icon",
    attrs: {
      "viewBox": "0 0 48 48",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-000000",
    attrs: {
      "d": "M23 48.049a.996.996 0 0 1-.43-.097l-21-10a1 1 0 0 1-.57-.903v-31a1.002 1.002 0 0 1 1.43-.903L23 14.941l20.57-9.796a1 1 0 0 1 1.43.904v31a1 1 0 0 1-.57.903l-21 10a.996.996 0 0 1-.43.097zM3 36.417l20 9.524 20-9.524V7.633l-19.57 9.319a1.002 1.002 0 0 1-.859 0L3 7.633v28.784z",
      "fill": "#9eabd6"
    }
  }), _c('path', {
    staticClass: "fill-000000",
    attrs: {
      "d": "M23 12.204 5.567 3.903a1 1 0 0 1 .86-1.807L23 9.989l16.573-7.893a1 1 0 0 1 .86 1.807L23 12.204zM22 16.049h2v31h-2z",
      "fill": "#9eabd6"
    }
  })]), _c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Для быстрого знакомства с функционалом сервиса мы подготовили для вас набор обучающих материалов. ")]), _c('svg', {
    staticClass: "welcome-arrow",
    attrs: {
      "enable-background": "new 0 0 32 32",
      "height": "32px",
      "id": "Слой_1",
      "version": "1.1",
      "viewBox": "0 0 32 32",
      "width": "32px",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z",
      "fill": "#121313",
      "fill-rule": "evenodd",
      "id": "Chevron_Right"
    }
  })])]), _c('router-link', {
    staticClass: "welcome-block",
    attrs: {
      "to": '/profile/plan'
    }
  }, [_c('svg', {
    staticClass: "welcome-icon",
    attrs: {
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-000000",
    attrs: {
      "d": "M9 6h2v14H9zm4 2h2v12h-2zm4-4h2v16h-2zM5 12h2v8H5z",
      "fill": "#9eabd6"
    }
  })]), _c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Ваш аккаунт успешно создан. Для доступа к полному функционалу сервиса выберите и оплатите тарифный план ")]), _c('svg', {
    staticClass: "welcome-arrow",
    attrs: {
      "enable-background": "new 0 0 32 32",
      "height": "32px",
      "id": "Слой_1",
      "version": "1.1",
      "viewBox": "0 0 32 32",
      "width": "32px",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z",
      "fill": "#121313",
      "fill-rule": "evenodd",
      "id": "Chevron_Right"
    }
  })])]), _c('router-link', {
    staticClass: "welcome-block",
    attrs: {
      "to": '/contact'
    }
  }, [_c('svg', {
    staticClass: "welcome-icon",
    attrs: {
      "fill": "none",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    staticClass: "fill-212121",
    attrs: {
      "d": "M9.25 7.307C9.09 7.491 9 7.745 9 8a.5.5 0 0 1-1 0c0-.473.161-.97.5-1.354C8.847 6.252 9.36 6 10 6s1.153.252 1.5.646c.339.385.5.88.5 1.354 0 .49-.116.87-.302 1.19-.163.279-.376.5-.545.677l-.042.043c-.186.195-.329.354-.434.554-.1.191-.177.444-.177.829a.5.5 0 1 1-1 0c0-.532.11-.947.291-1.293.177-.337.41-.584.598-.781l.022-.023c.188-.197.322-.337.423-.51.095-.163.166-.369.166-.686 0-.255-.089-.51-.25-.693C10.597 7.134 10.36 7 10 7s-.597.134-.75.307Zm.75 6.905a.7.7 0 1 0 0-1.399.7.7 0 0 0 0 1.4ZM2 10a8 8 0 1 1 4.262 7.075l-3.64.91a.5.5 0 0 1-.607-.606l.91-3.641A7.968 7.968 0 0 1 2 10Zm8-7a7 7 0 0 0-6.107 10.425.5.5 0 0 1 .05.366l-.756 3.022 3.022-.756a.5.5 0 0 1 .366.05A7 7 0 1 0 10 3Z",
      "fill": "#9eabd6"
    }
  })]), _c('p', {
    staticClass: "welcome-text"
  }, [_vm._v(" Если у вас есть какие-либо вопросы по работе сервиса, обязательно напишите нам. Мы всегда рады помочь! ")]), _c('svg', {
    staticClass: "welcome-arrow",
    attrs: {
      "enable-background": "new 0 0 32 32",
      "height": "32px",
      "id": "Слой_1",
      "version": "1.1",
      "viewBox": "0 0 32 32",
      "width": "32px",
      "xml:space": "preserve",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  }, [_c('path', {
    attrs: {
      "clip-rule": "evenodd",
      "d": "M21.698,15.286l-9.002-8.999  c-0.395-0.394-1.035-0.394-1.431,0c-0.395,0.394-0.395,1.034,0,1.428L19.553,16l-8.287,8.285c-0.395,0.394-0.395,1.034,0,1.429  c0.395,0.394,1.036,0.394,1.431,0l9.002-8.999C22.088,16.325,22.088,15.675,21.698,15.286z",
      "fill": "#121313",
      "fill-rule": "evenodd",
      "id": "Chevron_Right"
    }
  })])])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };