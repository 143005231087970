import { SlideYUpTransition } from "vue2-transitions";
export default {
  data() {
    return {
      popup: false
    };
  },
  methods: {
    openCarrotquestChat() {
      window.carrotquest.open();
    }
  },
  components: {
    SlideYUpTransition
  }
};