var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('aside', {
    staticClass: "sidebar",
    class: _vm.collapsed ? 'collapsed' : 'normal'
  }, [_c('div', {
    staticClass: "sidebar__logo__wrap"
  }, [_c('div', {
    staticClass: "logo-image"
  }, [_c('router-link', {
    staticClass: "sidebar__logo",
    attrs: {
      "to": "/",
      "active-class": ""
    }
  }, [!_vm.collapsed ? _c('img', {
    attrs: {
      "src": require(`@/assets/images/logo.svg`),
      "alt": "Logo"
    }
  }) : _vm.collapsed ? _c('img', {
    attrs: {
      "src": require(`@/assets/images/logo-small.svg`),
      "alt": "Logo"
    }
  }) : _vm._e()]), !_vm.collapsed ? _c('span', {
    staticClass: "logo-sign"
  }, [_vm._v("®")]) : _vm._e()], 1), _c('span', {
    staticClass: "settings__tooltip"
  }, [_c('img', {
    staticClass: "sidebar__dots",
    attrs: {
      "src": require(`@/assets/images/icons/dots.svg`),
      "alt": "Dots"
    },
    on: {
      "click": _vm.toggleSidebar
    }
  }), _vm.sidebarOpen ? _c('span', {
    staticClass: "settings__tooltip__content"
  }, [_vm._v(" Скрыть меню ")]) : !_vm.sidebarOpen ? _c('span', {
    staticClass: "settings__tooltip__content"
  }, [_vm._v(" Показать меню ")]) : _vm._e()])]), _c('ul', {
    staticClass: "sidebar__ul",
    on: {
      "mouseenter": _vm.mouseEnterSidebar,
      "mouseleave": _vm.mouseLeaveSidebar
    }
  }, [_vm._l(_vm.menu, function (item) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !item.show || item.show(),
        expression: "!item.show || item.show()"
      }],
      key: item.id,
      staticClass: "sidebar__li",
      class: {
        'sidebar__li-big': item.icon,
        'sidebar__li-title': !item.route && !item.href,
        closed: !_vm.openedParts.includes(item.part)
      }
    }, [item.route ? _c('router-link', {
      staticClass: "sidebar__li-item sidebar__li-item-link",
      attrs: {
        "event": item.preventNavigation && item.preventNavigation() ? '' : 'click',
        "to": item.route
      },
      nativeOn: {
        "click": function ($event) {
          item.click && item.click($event);
        }
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": require(`@/assets/images/sidebar/${item.icon}`),
        "width": "32",
        "height": "28"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(item.title) + " ")])]) : item.href ? _c('a', {
      staticClass: "sidebar__li-item sidebar__li-item-link",
      attrs: {
        "href": item.href,
        "target": "_blank"
      },
      on: {
        "click": function ($event) {
          item.click && item.click($event);
        }
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": require(`@/assets/images/sidebar/${item.icon}`),
        "width": "32",
        "height": "28"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(item.title) + " ")])]) : _c('div', {
      staticClass: "sidebar__li-item",
      on: {
        "click": () => _vm.toggleSidebarPart(item.part)
      }
    }, [item.icon ? _c('img', {
      attrs: {
        "src": require(`@/assets/images/sidebar/${item.icon}`),
        "width": "32",
        "height": "28"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(item.title) + " ")]), item.icon ? _c('img', {
      staticClass: "sidebar__li-arrow",
      attrs: {
        "src": require(`@/assets/images/icons/arrow-sidebar.svg`),
        "alt": "Arrow"
      }
    }) : _vm._e()]), item.new ? _c('NewFeature', {
      staticClass: "label",
      attrs: {
        "type": item.labelColor
      }
    }) : _vm._e()], 1);
  }), _c('li', {
    staticClass: "sidebar__li-item"
  }, [_c('Plugin-btn', {
    attrs: {
      "collapsed": _vm.collapsed,
      "w100": ""
    }
  }, [_vm._v(" Расширение для браузеров ")])], 1), _vm._m(0), _c('li', {
    staticClass: "sidebar__li-item"
  }, [_c('div', {
    staticClass: "socials-links"
  }, [_c('a', {
    staticClass: "socials-links__item",
    attrs: {
      "href": "https://www.youtube.com/@salesfinder_ru",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/sidebar/socials/YT.svg`),
      "alt": "YT"
    }
  })]), _c('a', {
    staticClass: "socials-links__item",
    attrs: {
      "href": "https://t.me/salesfinder",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/sidebar/socials/TG.svg`),
      "alt": "TG"
    }
  })]), _c('a', {
    staticClass: "socials-links__item",
    attrs: {
      "href": "https://vk.com/salesfinder_ru",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/sidebar/socials/VK.svg`),
      "alt": "VK"
    }
  })])])])], 2)]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c('li', {
    staticClass: "sidebar__li-item"
  }, [_c('span', {
    staticClass: "socials-text"
  }, [_vm._v("Официальные каналы SalesFinder")])]);
}];
export { render, staticRenderFns };