// your should provide ref=modal for you Modal.vue component
export default {
    methods: {
        getModal() {
            return this.$refs.modal;
        },
        open() {
            this.onOpen && this.onOpen(...arguments);
            return this.getModal().open({ exclusive: arguments[0]?.exclusive });
        },
        process() {
            this.open(...arguments);
            return new Promise((resolve, reject) => {
                this.$on("complete", (e) => resolve(e));
                this.getModal().$on("close", () =>
                    reject(Error("ModalClosed"))
                );
            });
        },
        close() {
            this.onClose && this.onClose(...arguments);
            return this.getModal().close();
        },
    },
};
