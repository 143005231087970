var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "app-wrap",
    class: _vm.$store.getters.themeClass,
    attrs: {
      "id": "app"
    }
  }, [_c(_vm.layout, {
    tag: "component"
  }, [_c('router-view')], 1), _c('portal-target', {
    ref: "portal",
    attrs: {
      "name": `__modals`,
      "multiple": ""
    }
  }), _c('div', {
    key: _vm.$route.path
  }, _vm._l(_vm.modals, function (modal) {
    return _c(modal.component, {
      key: modal.id,
      ref: `modal-${modal.id}`,
      refInFor: true,
      tag: "component",
      attrs: {
        "data-id": modal.id
      }
    });
  }), 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };