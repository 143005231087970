import AppSidebar from "@/components/AppSidebar.vue";
import AppHeader from "@/components/Header/AppHeader.vue";
import ModalWelcome from "@/components/modals/ModalWelcome";
import SelfDemo from "@/components/Alert/SelfDemo.vue"; //##SELF-DEMO-OFF##

import NewsBar from "@/components/Alert/NewsBar.vue";
import dayjs from "dayjs";
export default {
  data() {
    let waitForMountedResolver;
    const waitForMountedPromise = new Promise(resolve => {
      waitForMountedResolver = resolve;
    });
    return {
      waitForMountedPromise,
      waitForMountedResolver,
      showSelfDemo: true,
      newsBar_Show: process.env.VUE_APP_NEWS_BAR_TEXT ? true : false,
      newsBar_Text: process.env.VUE_APP_NEWS_BAR_TEXT,
      newsBar_Btn: process.env.VUE_APP_NEWS_BAR_BTN,
      newsBar_Link: process.env.VUE_APP_NEWS_BAR_LINK,
      newsBar_Class: process.env.VUE_APP_NEWS_BAR_CLASS
      // theme: this.$store.getters.themeClass,
    };
  },
  components: {
    AppSidebar,
    AppHeader,
    ModalWelcome,
    SelfDemo,
    // ##SELF-DEMO-OFF##
    NewsBar
  },
  mounted() {
    document.documentElement.classList.add("min-width");
    this.waitForMountedResolver();

    //console.log('58: process.env.VUE_APP_NEWS_BAR_TEXT=',process.env.VUE_APP_NEWS_BAR_TEXT)
    this.newsBar_Show = process.env.VUE_APP_NEWS_BAR_TEXT ? true : false;
    //console.log('62: this.newsBar_Show=',this.newsBar_Show)
  },
  watch: {
    $route: {
      async handler(route) {
        if (route.params.showWelcomeModal) {
          await this.waitForMountedPromise;
          this.$refs.ModalWelcome.open();
        }
      },
      immediate: true
    },
    "$store.getters.themeClass": function (val) {
      this.theme = val;
    }
  },
  destroyed() {
    document.documentElement.classList.remove("min-width");
  },
  computed: {
    newsBar_EndTime() {
      if (process.env.VUE_APP_NEWS_BAR_ENDTIME) {
        let EndTime = process.env.VUE_APP_NEWS_BAR_ENDTIME;
        //console.log('89: EndTime=',EndTime)
        let Now = dayjs().format("YYYY-MM-DD HH:mm");
        //console.log('91: Now=',Now)
        if (EndTime < Now) {
          //console.log('94: 1')
          return 1;
        }
        //console.log('97: 0')
        return 0;
      } else {
        //console.log('100: 0')
        return 0;
      }
    },
    user() {
      let user_w_tarif = null == this.$store.state.billing.currentSubscriptions || this.$store.state.billing.currentSubscriptions.length == 0 ? 0 : 1;

      //console.log('user_w_tarif=',user_w_tarif)

      //user_w_tarif=0; //!dbg: для показа транспоранта в любом случае

      return user_w_tarif;
    }
  }
};