import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    },
    qSelfDemo: {
      required: false,
      default: 0
    }
  },
  mounted() {},
  methods: {
    submit({
      close
    }) {
      close();
      this.$emit("complete");
    }
  },
  computed: {
    title() {
      return this.qSelfDemo == 1 ? "Доступ успешно активирован!" : "Ваш запрос отправлен";
    },
    actions() {
      return [{
        name: "submit",
        text: this.qSelfDemo == 1 ? "Приступить к тестированию" : "Закрыть",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};