import Vue from "vue";
import { getMarketplacesListArray } from "@/utils/enums";
import { dateRangeBoundaries, dateRangeFromDate, today } from "@/utils/dates";
import { filtersToObject, jsonToFormData } from "@/utils/newModule";
import { dateFormatAlt } from "@/utils/dates";

const select_marketplace_items = [...getMarketplacesListArray()];

export default {
    namespaced: true,
    state: {
        notValidTokens: [],
        validTokens: [],
        hasTokens: false,
        hasValidTokens: false,
        hasInitialReadyTokens: true,
        loading: false,
        loaded: false,
        tokens: [],
        selectedTokens:
            JSON.parse(localStorage.getItem("repricerSelectedTokens")) || [],
        dates: [],
        calendar: null,
        calendarBoundaries: [],
        marketplace:
            JSON.parse(localStorage.getItem("repricerMp")) ||
            select_marketplace_items[0],
    },
    mutations: {
        SET_TOKENS(state, data) {
            state.tokens = data;
        },
        SET_NOT_VALID_TOKENS(state, data) {
            state.notValidTokens = data;
        },
        SET_VALID_TOKENS(state, data) {
            state.validTokens = data;
        },
        SET_HAS_TOKENS(state, data) {
            state.hasTokens = data;
        },
        SET_HAS_VALID_TOKENS(state, data) {
            state.hasValidTokens = data;
        },
        SET_LOADING(state, data) {
            state.loading = data;
            if (!data) {
                state.loaded = true;
            }
        },
        SET_HAS_INITIAL_READY_TOKENS(state, data) {
            state.hasInitialReadyTokens = data;
        },
        SET_CALENDAR(state, data) {
            state.calendar = data;
        },
        SET_DATES(state, data) {
            state.dates = data;
        },
        SET_CALENDAR_BOUNDARIES(state, data) {
            state.calendarBoundaries = data;
        },
        SET_SELECTED_TOKENS(state, data) {
            state.selectedTokens = data;
        },
        SET_MARKETPLACE(state, data) {
            state.marketplace = data;
        },
    },
    actions: {
        async setHasTokens({ dispatch }) {
            await dispatch("getTokens", {});
        },
        setMarketplace({ commit }, data) {
            commit("SET_MARKETPLACE", data);
        },
        setSelectedTokens({ commit }, data) {
            localStorage.setItem(
                "repricerSelectedTokens",
                JSON.stringify(data)
            );
            commit("SET_SELECTED_TOKENS", data);
        },
        setCalendar({ commit, state }) {
            if (state.calendar === null) {
                if (!state.calendarBoundaries) {
                    commit("SET_CALENDAR", null);
                    return;
                }
                const lastAvailableDate =
                    state.calendarBoundaries?.[1] || today();
                const monthRange = dateRangeFromDate(lastAvailableDate, 30);
                // const queryRange = dateRangeUnsafe(this.$route.query?.date, this.$route.query?.date2)
                commit(
                    "SET_CALENDAR",
                    dateRangeBoundaries(
                        [monthRange[0], monthRange[1]],
                        state.calendarBoundaries
                    )
                );
            }
        },
        updateCalendar({ commit }, data) {
            commit("SET_CALENDAR", data);
        },
        setDates({ commit }, data) {
            commit("SET_DATES", data);
        },
        setCalendarBoundaries({ commit, state }) {
            if (state.calendarBoundaries.length < 2) {
                const calendarBoundaries = ["2022-03-26", today()];
                commit("SET_CALENDAR_BOUNDARIES", calendarBoundaries);
            }
        },
        /**
         * Загружает токены пользователя и задает state-свойства доступности
         */
        async getTokens({ commit, dispatch, state }) {
            commit("SET_LOADING", true);
            const { data } = await Vue.api.post(
                "/service/seller/repricer/get_user_keys"
            );

            const tokens = data?.data;
            commit("SET_TOKENS", tokens);
            const filteredSelectedTokens = state.selectedTokens.filter(
                (token) => {
                    return tokens.some((item) => item.id === token.id);
                }
            );
            await dispatch("getNotValidTokens");
            await dispatch("getValidTokens");
            dispatch("setSelectedTokens", filteredSelectedTokens);
            const hasTokens = !!tokens.length;
            commit("SET_HAS_TOKENS", hasTokens);
            const hasValidTokens = !!tokens.length;
            commit("SET_HAS_VALID_TOKENS", hasValidTokens);
            const hasInitialReadyTokens = !!tokens.length;
            commit("SET_HAS_INITIAL_READY_TOKENS", hasInitialReadyTokens);
            commit("SET_LOADING", false);
            return data;
        },
        // Выборка по токенам пользователя
        async queryTokens({ dispatch }) {
            const { data } = await Vue.api.post(
                "/service/seller/repricer/get_user_keys"
            );
            await dispatch("getTokens");
            return data;
        },
        async getNotValidTokens({ commit }) {
            const { data } = await Vue.api.post(
                "/service/seller/repricer/get_not_valid_user_keys"
            );
            const tokens = data?.data;
            commit("SET_NOT_VALID_TOKENS", tokens);
            return data;
        },
        async getValidTokens({ commit }) {
            const { data } = await Vue.api.post(
                "/service/seller/repricer/get_downloadable_user_keys"
            );
            const tokens = data?.data;
            commit("SET_VALID_TOKENS", tokens);
            return data;
        },

        async createToken({ dispatch }, { token, name }) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/feedback/token",
                {
                    token,
                    name,
                }
            );
            //console.log("created token");
            await dispatch("getTokens");
            return data;
        },

        async updateToken(ctx, { id, name, token }) {
            const { data } = await Vue.api.patch(
                `/service/seller/wb/feedback/token/${id}`,
                {
                    name,
                    token,
                }
            );
            return data;
        },
        async removeToken({ dispatch }, { id }) {
            const { data } = await Vue.api.delete(
                `/service/seller/wb/feedback/token/${id}`
            );
            await dispatch("getTokens");
            return data;
        },
        async getHighlights(
            context,
            {
                // date, date2,
                tokens,
            }
        ) {
            // date = dateFormatAlt(date);
            // date2 = dateFormatAlt(date2);
            let params = {
                "filter[logic]": "and",
                // "filter[filters][0][field]": "last_change_date",
                // "filter[filters][0][operator]": "gt",
                // "filter[filters][0][value]": date,
                // "filter[filters][1][field]": "last_change_date",
                // "filter[filters][1][operator]": "lt",
                // "filter[filters][1][value]": date2,
                "filter[filters][0][field]": "user_key_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
            };
            const { data } = await Vue.api.post(
                "/service/seller/repricer/get_highlights",
                jsonToFormData(params)
            );

            return data.data;
        },
        async getMyProducts(
            context,
            {
                page = 1,
                page_size = 10,
                date,
                date2,
                tokens,
                sort = "date_add",
                sort_dir = "desc",
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "user_key_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            if (date) {
                date = dateFormatAlt(date);
                params = {
                    ...params,
                    "filter[filters][1][field]": "date_add",
                    "filter[filters][1][operator]": "gt",
                    "filter[filters][1][value]": date,
                };
            }
            if (date2) {
                date2 = dateFormatAlt(date2);
                params = {
                    ...params,
                    "filter[filters][2][field]": "date_add",
                    "filter[filters][2][operator]": "lt",
                    "filter[filters][2][value]": date2,
                };
            }
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_sku_product_list`,
                jsonToFormData(params)
            );
            return data;
        },
        async getProductsManagement(
            context,
            {
                page = 1,
                page_size = 10,
                date,
                date2,
                tokens,
                sort = "date_add",
                sort_dir = "desc",
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "user_key_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            if (date) {
                date = dateFormatAlt(date);
                params = {
                    ...params,
                    "filter[filters][1][field]": "date_add",
                    "filter[filters][1][operator]": "gt",
                    "filter[filters][1][value]": date,
                };
            }
            if (date2) {
                date2 = dateFormatAlt(date2);
                params = {
                    ...params,
                    "filter[filters][2][field]": "date_add",
                    "filter[filters][2][operator]": "lt",
                    "filter[filters][2][value]": date2,
                };
            }
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_sku_dynamic_list`,
                jsonToFormData(params)
            );
            return data;
        },
        async getEventsLog(
            context,
            {
                page = 1,
                page_size = 10,
                date,
                date2,
                tokens,
                product,
                script,
                sort = "date_add",
                sort_dir = "desc",
                ...filters
            }
        ) {
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "user_key_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            if (date) {
                date = dateFormatAlt(date);
                params = {
                    ...params,
                    "filter[filters][1][field]": "date_add",
                    "filter[filters][1][operator]": "gt",
                    "filter[filters][1][value]": date,
                };
            }
            if (date2) {
                date2 = dateFormatAlt(date2);
                params = {
                    ...params,
                    "filter[filters][2][field]": "date_add",
                    "filter[filters][2][operator]": "lt",
                    "filter[filters][2][value]": date2,
                };
            }
            if (product) {
                params = {
                    ...params,
                    "filter[filters][3][field]": "sku",
                    "filter[filters][3][operator]": "contains",
                    "filter[filters][3][value][]": product,
                };
            }
            if (script) {
                params = {
                    ...params,
                    "filter[filters][4][field]": "script_name",
                    "filter[filters][4][operator]": "contains",
                    "filter[filters][4][value][]": script,
                };
            }
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_sku_history_list`,
                jsonToFormData(params)
            );
            return data;
        },
        async getScripts(
            context,
            {
                page = 1,
                page_size = 10,
                // date,
                // date2,
                tokens,
                sort = "last_change_date",
                sort_dir = "desc",
                ...filters
            }
        ) {
            // date = dateFormatAlt(date);
            // date2 = dateFormatAlt(date2);
            const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                // "filter[filters][0][field]": "last_change_date",
                // "filter[filters][0][operator]": "gt",
                // "filter[filters][0][value]": date,
                // "filter[filters][1][field]": "last_change_date",
                // "filter[filters][1][operator]": "lt",
                // "filter[filters][1][value]": date2,
                "filter[filters][0][field]": "user_key_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": tokens,
                ...otherFilters,
                "sort[0][field]": sort,
                "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_script_list`,
                jsonToFormData(params)
            );
            return data;
        },
        async getScriptCompetitors(context, { id }) {
            let params = {
                "filter[logic]": "and",
                "filter[filters][0][field]": "repricer_script_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": id,
            };
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_product_competitor_list`,
                jsonToFormData(params)
            );
            return data;
        },
        async getScriptTypes() {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_script_type`
            );
            return data;
        },
        async getScript(context, id) {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_script/`,
                {
                    id,
                }
            );
            return data.data;
        },
        async addScript(context, body) {
            const data = await Vue.api.post(
                `/service/seller/repricer/create_script`,
                body
            );
            return data;
        },
        async updateScript(context, body) {
            const data = await Vue.api.post(
                `/service/seller/repricer/update_script/`,
                body
            );
            return data;
        },
        async removeScript(context, { id }) {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/destroy_script/`,
                {
                    id,
                }
            );
            return data;
        },
        async checkScript(context, template) {
            const { data } = await Vue.api.post(
                "/service/seller/wb/feedback/script/mock",
                { template }
            );
            return data.data;
        },
        async toggleScript(context, id) {
            const { data } = await Vue.api.post(
                `/service/seller/wb/feedback/script/${id}/toggle`
            );
            return data;
        },
        async loadProducts(context, { page = 1, page_size = 10, id }) {
            // const otherFilters = filtersToObject(filters);
            let params = {
                take: page_size,
                pageSize: page_size,
                page: page,
                skip: (page - 1) * page_size,
                "filter[logic]": "and",
                "filter[filters][0][field]": "repricer_script_id",
                "filter[filters][0][operator]": "=",
                "filter[filters][0][value][]": id,
                // ...otherFilters,
                // "sort[0][field]": sort,
                // "sort[0][dir]": sort_dir,
            };
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_product_list/`,
                jsonToFormData(params)
            );
            return data;
        },
        async addProducts(context, list) {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/create_product/`,
                {
                    sku_range_price_list: list,
                }
            );
            return data;
        },
        async updateProducts(context, list) {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/update_product/`,
                {
                    product_list: list,
                }
            );
            return data;
        },
        async removeProducts(context, list) {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/destroy_product/`,
                {
                    product_id_list: list,
                }
            );
            return data;
        },
        async getRepricerLimit() {
            const { data } = await Vue.api.post(
                `/service/seller/repricer/get_repricer_sku_quota_count/`
            );
            return data;
        },
    },
};
