import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async getListM(context, { id }) {
            const { data } = await Vue.api.get("/listM/getListM", {
                params: {
                    id,
                },
            });
            return data;
        },

        async addListM(context, { title, entity, monitor, mp, description }) {
            const { data: responseData } = await Vue.api.post(
                "/listM/addListM",
                {
                    title,
                    entity,
                    monitor,
                    mp,
                    description,
                }
            );
            return responseData;
        },
        async updateListM(context, { id, title, description }) {
            const { data: responseData } = await Vue.api.put(
                "/listM/updateListM",
                {
                    id,
                    title,
                    description,
                }
            );
            return responseData;
        },
        async removeListM(context, { id }) {
            const { data: responseData } = await Vue.api.delete(
                "/listM/removeListM",
                {
                    params: {
                        id,
                    },
                }
            );
            return responseData;
        },
        async listMImport(context, { id, lines }) {
            const { data: responseData } = await Vue.api.post(
                "/listM/listMImport",
                {
                    id,
                    lines,
                }
            );
            return responseData;
        },
        async removeListMItems(context, { id, items }) {
            const { data: responseData } = await Vue.api.delete(
                "/listM/removeListMItems",
                {
                    params: {
                        id,
                        items,
                    },
                }
            );
            return responseData;
        },
        async getListMs(context, queryParams) {
            const { data } = await Vue.api.get("/listM/getListMs", {
                params: {
                    ...queryParams,
                },
            });
            return data;
        },

        //*=============================================
        async getListMProducts(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/listM/getListMProducts", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        /*
            // async getListMProducts_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/listM/getListMProducts_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            */
        async getListMProductsNewNameDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMProductsNewNameDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },

        //*===========================================
        async getListMBrands(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/listM/getListMBrands", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getListMBrands_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/listM/getListMBrands_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            */

        async getListMBrandsNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMBrandsNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getListMBrandsNewSellersDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMBrandsNewSellersDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },

        //*============================================
        async getListMSellers(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/listM/getListMSellers", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getListMSellers_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/listM/getListMSellers_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            */

        async getListMSellersNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMSellersNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getListMSellersNewBrandsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMSellersNewBrandsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },

        //*===============================================
        async getListMCategories(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get("/listM/getListMCategories", {
                params: {
                    id,
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },

        /*
            // async getListMCategories_noAuth(context, queryParams) {
            //     const { id, date, date2 } = queryParams;
            //     const { data } = await Vue.api.get("/listM/getListMCategories_noAuth", {
            //         params: {
            //             id,
            //             date,
            //             date2,
            //             // other params like pagination, sorting, filtering is dynamic
            //             ...queryParams,
            //         },
            //     });
            //     return data;
            // },
            */

        async getListMCategoriesNewProductsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMCategoriesNewProductsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getListMCategoriesNewBrandsDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMCategoriesNewBrandsDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
        async getListMCategoriesNewSellersDetail(context, queryParams) {
            const { id, date, date2 } = queryParams;
            const { data } = await Vue.api.get(
                "/listM/getListMCategoriesNewSellersDetail",
                {
                    params: {
                        id,
                        date,
                        date2,
                        // other params like pagination, sorting, filtering is dynamic
                        ...queryParams,
                    },
                }
            );
            return data;
        },
    },
};
