var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('modal', {
    ref: "modal",
    attrs: {
      "title": _vm.title,
      "width": 420,
      "actions": _vm.actions
    },
    on: {
      "close": function ($event) {
        return _vm.$emit('complete');
      }
    }
  }, [_vm.qSelfDemo == 1 ? _c('span', [_vm._v(" Ваш доступ успешно активирован. "), _c('br'), _c('br'), _vm._v(" Уверены, что SalesFinder принесет вам пользу и увеличит продажи, желаем приятного тестирования! "), _c('br'), _c('br'), _vm._v(" На ваш Email мы отправили письмо с полезными инструкциями по работе с сервисом. "), _c('br'), _c('br'), _vm._v(" Если у вас возникнут какие-либо вопросы при изучении функционала, обязательно "), _c('a', {
    attrs: {
      "href": "https://salesfinder.ru/contact",
      "target": "_blank"
    }
  }, [_vm._v(" напишите нам ")]), _vm._v(" - наша команда с радостью поможет! ")]) : _c('span', [_vm._v(" Ваш запрос на 24-часовое тестирование успешно отправлен. "), _c('br'), _c('br'), _vm._v(" В ближайшее время наш менеджер свяжется с Вами по указанному Вами телефону для уточнения деталей ")])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };