//* /MP/salesfinder/src/components/CarrotQuest/CarrotQuest.js
// 2023-10-31

////import dayjs from "dayjs";
////const FORMAT = "YYYY-MM-DD";

/*==========================================
export async function CQ_register(user){
//console.log('CQ_register.5: user=',user)

    let carrotquest = window.carrotquest

    carrotquest.auth(user.user_id, user.carrotquest_auth_hash);
    carrotquest.identify({
        '$email': user.user_email_address,
        '$phone': user.user_phone
    })
}
/**/


//*==========================================
export async function CQ_tarif($api, user){
////console.log('CQ_tarif.24: user=',user)

    if( !user ) return;
    ////console.log('CQ_tarif.28: user=', user)

    const CQ_TARIF = "CQ_tarif_date_"+user.user_id.toString();

    //первичная установка
    let date_min_session, qMustCall_CQ_tarif=0, cqObj={};
    if( !window.sessionStorage.getItem(CQ_TARIF) ){

        if( user.carrotquest_currentSubscription ){
            date_min_session = user.carrotquest_currentSubscription.date_end;
            if( user.carrotquest_currentSubscription.date_disable
                && user.carrotquest_currentSubscription.date_disable < date_min_session ){
                date_min_session = user.carrotquest_currentSubscription.date_disable;
            }
            if( user.carrotquest_currentSubscription.date_expire_handled
                && user.carrotquest_currentSubscription.date_expire_handled < date_min_session ){
                date_min_session = user.carrotquest_currentSubscription.date_expire_handled;
            }
        }else{
            date_min_session = user.createdAt.slice(0,10)
        }

        ////console.log('CQ_tarif.51: window.sessionStorage.setItem(CQ_tarif_date)=', date_min_session)
        window.sessionStorage.setItem(CQ_TARIF, date_min_session);

        /*
        // cqObj = {
        //     '$email': user.user_email_address,
        //     '$phone': user.user_phone,
        // }
        // //console.log('CQ_tarif.58: cqObj=', cqObj)

        // carrotquest.auth(user.user_id, user.carrotquest_auth_hash);
        // carrotquest.identify(cqObj)
        */

        qMustCall_CQ_tarif = 1;
    }
    date_min_session = window.sessionStorage.getItem(CQ_TARIF);

    ////console.log('CQ_tarif.58: date_min_session=', date_min_session)
    ////console.log('CQ_tarif.59: qMustCall_CQ_tarif=', qMustCall_CQ_tarif)

    let date_min;
    if( user.carrotquest_currentSubscription ){
        date_min = user.carrotquest_currentSubscription.date_end;
        if( user.carrotquest_currentSubscription.date_disable
            && user.carrotquest_currentSubscription.date_disable < date_min ){
            date_min = user.carrotquest_currentSubscription.date_disable;
        }
        if( user.carrotquest_currentSubscription.date_expire_handled
            && user.carrotquest_currentSubscription.date_expire_handled < date_min ){
            date_min = user.carrotquest_currentSubscription.date_expire_handled;
        }
    }else{
        date_min = date_min_session;
    }

    ////console.log('CQ_tarif.57: date_min=', date_min)

    if( !qMustCall_CQ_tarif && (date_min === date_min_session) ) return;
    window.sessionStorage.setItem(CQ_TARIF, date_min);


    // carrotquest:
    let carrotquest = window.carrotquest

    const { data: subscribe } = await $api.get("/billing/subscriptions", {
        params: {
            where: {
                user_id: user.user_id,
            },
            itemsPerPage: 10000,
        }
    });
    ////console.log('CQ_tarif.75: subscribe=', subscribe)

    cqObj = {
        '$email': user.user_email_address,
        '$phone': user.user_phone,

        'тариф': subscribe.items[0] ? subscribe.items[0].plan.name : 'Free',
        'срок подписки': subscribe.items[0] ? subscribe.items[0].date_end.toString().slice(0,10) : '-',
        'кол-во оплат': subscribe.items.filter(el => el.avg_price > 0).length,
    }
    /////console.log('CQ_tarif.104: cqObj=', cqObj)

    carrotquest.auth(user.user_id, user.carrotquest_auth_hash);
    carrotquest.identify(cqObj)
}
