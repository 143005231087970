import axios from "axios";
import store from "@/store";

export default {
    install(Vue) {
        const api = axios.create({
            baseURL: process.env.VUE_APP_API_URL,
            withCredentials: true,
        });
        api.interceptors.request.use(
            function (config) {
                // Do something before request is sent
                if (config.params?.action === "export") {
                    config.responseType = "blob";
                }

                return config;
            },
            function (error) {
                // Do something with request error
                return Promise.reject(error);
            }
        );

        const checkIsRefreshRequired = (response) => {
            if (response.headers && store.state.user.user !== null) {
                const uidFromHeaders = response.headers["x-uid"]
                    ? Number(response.headers["x-uid"])
                    : null;
                const uidFromStore = store.state.user.user?.user_id || null;
                if (uidFromStore && uidFromHeaders !== uidFromStore) {
                    return true;
                }
                return false;
            }
        };

        api.interceptors.response.use(
            function (response) {
                if (checkIsRefreshRequired(response)) {
                    window.document.location.reload();
                    return new Promise(() => {}); // бесконечная загрузка
                }
                return response;
            },
            function (error) {
                let response = error?.response;

                if (checkIsRefreshRequired(response)) {
                    window.document.location.reload();
                    return new Promise(() => {}); // бесконечная загрузка
                }
                // outside 2xx code
                if (
                    error?.response?.status === 402 &&
                    error?.response?.data?.type
                ) {
                    try {
                        Vue.access.throwFunction(error?.response?.data);
                    } catch (e) {
                        //
                    }
                }
                return Promise.reject(error);
            }
        );

        Vue.api = api;
        Vue.prototype.$api = api;
    },
};
