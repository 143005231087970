var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "checkbox"
  }, [_c(_vm.wrapper, {
    tag: "component",
    staticClass: "custom-checkbox",
    class: {
      centered: _vm.centered,
      padding: _vm.padding
    }
  }, [_c('input', {
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.shouldBeChecked,
      "value": _vm.value
    },
    on: {
      "change": _vm.updateInput
    }
  }), !_vm.shouldBeChecked & !_vm.rounded ? _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "rx": "2",
      "fill": "white"
    }
  }), _c('rect', {
    attrs: {
      "x": "0.5",
      "y": "0.5",
      "width": "15",
      "height": "15",
      "rx": "1.5",
      "fill": "white",
      "stroke": "#D9D9D9"
    }
  })]) : _vm._e(), _vm.shouldBeChecked & !_vm.rounded ? _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "rx": "2",
      "fill": "white"
    }
  }), _c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "rx": "2",
      "fill": _vm.colorLocal
    }
  }), _c('path', {
    attrs: {
      "d": "M6.60545 12.2217L6.58783 12.2394L3.18799 8.83952L4.62067 7.40684L6.60551 9.39169L11.3795 4.61768L12.8122 6.05035L6.62313 12.2394L6.60545 12.2217Z",
      "fill": "white"
    }
  })]) : _vm._e(), !_vm.shouldBeChecked & _vm.rounded ? _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "x": "0.75",
      "y": "0.75",
      "width": "14.5",
      "height": "14.5",
      "rx": "3.25",
      "fill": "white",
      "stroke": "#D9D9D9",
      "stroke-width": "1.5"
    }
  })]) : _vm._e(), _vm.shouldBeChecked & _vm.rounded ? _c('svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "rx": "4",
      "fill": "white"
    }
  }), _c('rect', {
    attrs: {
      "width": "16",
      "height": "16",
      "rx": "4",
      "fill": _vm.colorLocal
    }
  }), _c('path', {
    attrs: {
      "d": "M6.60545 12.2217L6.58783 12.2394L3.18799 8.83952L4.62067 7.40684L6.60551 9.39169L11.3795 4.61768L12.8122 6.05035L6.62313 12.2394L6.60545 12.2217Z",
      "fill": "white"
    }
  })]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.title))])])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };