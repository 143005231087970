import Vue from "vue";
export default {
    namespaced: true,
    actions: {
        async state() {
            const { data } = await Vue.api.get("/partner/state", {});
            return data;
        },
        async overviewAll(context, { date, date2 }) {
            const { data } = await Vue.api.get("/partner/overviewAll", {
                params: {
                    date,
                    date2,
                },
            });
            return data;
        },
        async getVisits(context, queryParams) {
            const { date, date2 } = queryParams;
            const { data } = await Vue.api.get("/partner/getVisits", {
                params: {
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getIncomes(context, queryParams) {
            const { date, date2 } = queryParams;
            const { data } = await Vue.api.get("/partner/getIncomes", {
                params: {
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async getWithdrawals(context, queryParams) {
            const { date, date2 } = queryParams;
            const { data } = await Vue.api.get("/partner/getWithdrawals", {
                params: {
                    date,
                    date2,
                    // other params like pagination, sorting, filtering is dynamic
                    ...queryParams,
                },
            });
            return data;
        },
        async postWithdrawal(context, body) {
            const { data } = await Vue.api.post("/partner/withdrawal", body);
            return data;
        },
    },
};
