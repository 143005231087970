var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "global-search-block-wrap"
  }, [_c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: () => _vm.collapseSearch(true),
      expression: "() => collapseSearch(true)"
    }],
    staticClass: "global-search-block",
    class: {
      'global-search-block-active': _vm.input_focused,
      'global-search-block-collapsed': _vm.collapsed,
      tall: _vm.tall
    }
  }, [!_vm.collapsed ? _c('div', {
    staticClass: "global-search-settings",
    class: {
      'global-search-settings-button-active': _vm.show_settings_popup
    }
  }, [_c('div', {
    staticClass: "global-search-settings-button",
    on: {
      "click": _vm.toogleSettingsPopup
    }
  }, [_c('div', {
    staticClass: "global-search-settings-indicator",
    class: {
      'global-search-settings-indicator-active': _vm.activeSettingsIndicator
    }
  }), _c('div', {
    staticClass: "global-search-settings-text"
  }, [_vm._v(" Настройки поиска ")]), _c('img', {
    attrs: {
      "src": require(`@/assets/images/header/arrow.svg`)
    }
  })]), _c('slide-y-up-transition', [_vm.show_settings_popup ? _c('div', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.toogleSettingsPopup,
      expression: "toogleSettingsPopup"
    }],
    staticClass: "global-search-settings-popup"
  }, [_c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "Wildberries"
    },
    model: {
      value: _vm.cb_wb,
      callback: function ($$v) {
        _vm.cb_wb = $$v;
      },
      expression: "cb_wb"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "OZON"
    },
    model: {
      value: _vm.cb_ozon,
      callback: function ($$v) {
        _vm.cb_ozon = $$v;
      },
      expression: "cb_ozon"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-line"
  }), _c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "Категории"
    },
    model: {
      value: _vm.cb_category,
      callback: function ($$v) {
        _vm.cb_category = $$v;
      },
      expression: "cb_category"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "Товары"
    },
    model: {
      value: _vm.cb_product,
      callback: function ($$v) {
        _vm.cb_product = $$v;
      },
      expression: "cb_product"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "Бренды"
    },
    model: {
      value: _vm.cb_brand,
      callback: function ($$v) {
        _vm.cb_brand = $$v;
      },
      expression: "cb_brand"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-item"
  }, [_c('checkbox', {
    attrs: {
      "title": "Продавцы"
    },
    model: {
      value: _vm.cb_seller,
      callback: function ($$v) {
        _vm.cb_seller = $$v;
      },
      expression: "cb_seller"
    }
  })], 1), _c('div', {
    staticClass: "global-search-settings-popup-line"
  }), _c('button', {
    staticClass: "btn-outline global-search-settings-popup-btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.resetSettings.apply(null, arguments);
      }
    }
  }, [_vm._v(" Сбросить ")]), _c('button', {
    staticClass: "btn-blue global-search-settings-popup-btn",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.applySettings.apply(null, arguments);
      }
    }
  }, [_vm._v(" Применить ")])]) : _vm._e()])], 1) : _vm._e(), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.header_search,
      expression: "header_search"
    }],
    ref: "input",
    staticClass: "global-search-input",
    attrs: {
      "type": "text",
      "placeholder": _vm.textPlaceholder
    },
    domProps: {
      "value": _vm.header_search
    },
    on: {
      "focus": _vm.focus_input,
      "blur": _vm.unfocus_input,
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.header_search = $event.target.value;
      }, _vm.change_input],
      "keydown": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        return _vm.select_prev_search_item.apply(null, arguments);
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        return _vm.select_next_search_item.apply(null, arguments);
      }],
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.select_search_item();
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "escape", undefined, $event.key, undefined)) return null;
        return _vm.unfocus_input(true);
      }]
    }
  }), !_vm.hideButton ? _c('button', {
    staticClass: "global-search-btn"
  }, [_c('img', {
    attrs: {
      "src": require(`@/assets/images/header/search.svg`)
    }
  })]) : _vm._e(), _c('slide-y-up-transition', [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.show_search_result && (_vm.search_results.length > 0 || !_vm.search_loading),
      expression: "\n                    show_search_result &&\n                    (search_results.length > 0 || !search_loading)\n                "
    }],
    staticClass: "global-search-result"
  }, [_vm.search_results.length ? _vm._l(_vm.search_results, function (res, i) {
    return _c(res.path ? 'router-link' : 'div', {
      key: i,
      ref: `result:${i}`,
      refInFor: true,
      tag: "component",
      staticClass: "global-search-result-item",
      class: {
        'global-search-result-item--active': i == _vm.search_input_manual_selected
      },
      attrs: {
        "to": res.path,
        "target": "_blank"
      },
      nativeOn: {
        "click": function ($event) {
          return _vm.onClick.apply(null, arguments);
        }
      }
    }, [_c('span', {
      staticClass: "global-search-result-title",
      domProps: {
        "innerHTML": _vm._s(res.title)
      }
    }), _c('span', {
      staticClass: "global-search-result-type",
      domProps: {
        "innerHTML": _vm._s(res.desc)
      }
    })]);
  }) : [_c('div', {
    staticClass: "no-data"
  }, [_vm._v("Ничего не найдено")])]], 2)])], 1)]);
};
var staticRenderFns = [];
export { render, staticRenderFns };