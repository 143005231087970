import Modal, { ModalMixin } from "@/components/modals/layouts/Default.vue";
export default {
  mixins: [ModalMixin],
  props: {
    id: {
      required: false
    }
  },
  data() {
    //{{feedback
    const initial_connect = {
      name: "",
      email: "",
      phone: ""
    };
    //}}
    return {
      //{{feedback
      initial_connect,
      connect: {
        ...initial_connect
      },
      //}}

      loading: false
    };
  },
  mounted() {},
  methods: {
    //{{feedback
    onOpen() {
      this.connect = {
        ...this.initial_connect
      };
    },
    //}}
    async submit({
      close
    }) {
      this.loading = true;
      try {
        //{{feedback
        const result = await this.$store.dispatch("feedback/send", {
          // eslint-disable-line
          name: this.connect.name,
          email_address: this.connect.email,
          phone_number: this.connect.phone,
          message: "запрос демо-доступа через сайт"
        });
        //}}

        /*
        await this.$store.dispatch("billing/setSelfDemoPlan", {
            param: { fake: "fake" },
        });
        */

        this.loading = false;
        close();
        this.$emit("complete");
      } catch (e) {
        this.loading = false;
      }
    }
  },
  computed: {
    //{{feedback
    valid() {
      var _this$connect$name, _this$connect$email, _this$connect$phone;
      return ((_this$connect$name = this.connect.name) === null || _this$connect$name === void 0 ? void 0 : _this$connect$name.length) && ((_this$connect$email = this.connect.email) === null || _this$connect$email === void 0 ? void 0 : _this$connect$email.length) && ((_this$connect$phone = this.connect.phone) === null || _this$connect$phone === void 0 ? void 0 : _this$connect$phone.length);
    },
    //}}

    actions() {
      return ["cancel", {
        name: "submit",
        //text: "Активировать доступ",  //self-demo
        text: "Запросить доступ",
        action: ({
          close
        }) => {
          this.submit({
            close
          });
        },
        disabled: !this.valid,
        //feedback
        loading: this.loading
      }];
    }
  },
  components: {
    Modal
  }
};