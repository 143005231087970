export default {
  name: "Warning",
  props: {
    text: String,
    textBtn: String,
    link: String,
    newsBarClass: {
      type: String,
      default: "green"
    },
    centered: {
      type: Boolean
    }
  }
};