export default {
    install(Vue) {
        const modal = {
            onOpen: null,
            onProcess: null,
            open() {
                this.onOpen && this.onOpen(...arguments);
            },
            process() {
                return this.onProcess && this.onProcess(...arguments);
            },
        };
        Vue.modal = modal;
        Vue.prototype.$modal = modal;
    },
};
