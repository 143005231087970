var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "app"
  }, [_c('app-sidebar'), _c('div', {
    staticClass: "app-rightside"
  }, [_c('app-header'), !_vm.user && _vm.showSelfDemo ? _c('self-demo', {
    on: {
      "activated": function ($event) {
        _vm.showSelfDemo = false;
      }
    }
  }) : _vm._e(), _vm.user && _vm.newsBar_Show && !_vm.newsBar_EndTime ? _c('news-bar', {
    attrs: {
      "text": _vm.newsBar_Text,
      "text-btn": _vm.newsBar_Btn,
      "link": _vm.newsBar_Link,
      "newsBarClass": _vm.newsBar_Class
    },
    on: {
      "close": function ($event) {
        _vm.newsBar_Show = false;
      }
    }
  }) : _vm._e(), _vm._t("default")], 2), _c('modal-welcome', {
    ref: "ModalWelcome"
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };