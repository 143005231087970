var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('a', {
    staticClass: "plugin-btn",
    class: {
      collapsed: _vm.collapsed,
      w100: _vm.w100,
      dark: _vm.dark
    },
    attrs: {
      "href": "https://help.salesfinder.ru/salesfinder-plugin",
      "target": "_blank"
    }
  }, [_c('img', {
    staticClass: "plugin-btn__img",
    attrs: {
      "src": require('@/assets/images/icons/browsers/chrome.svg'),
      "alt": "Icon"
    }
  }), !_vm.collapsed ? _c('img', {
    staticClass: "plugin-btn__img",
    attrs: {
      "src": require('@/assets/images/icons/browsers/yandex.svg'),
      "alt": "Icon"
    }
  }) : _vm._e(), !_vm.collapsed ? _c('span', {
    staticClass: "plugin-btn__text"
  }, [_vm._t("default")], 2) : _vm._e()]);
};
var staticRenderFns = [];
export { render, staticRenderFns };