var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_vm.isOpened ? _c('portal', {
    key: `k:${_vm.uid}`,
    attrs: {
      "to": "__modals",
      "name": `n-${_vm.uid}`
    }
  }, [_c('transition', {
    key: `t:${_vm.uid}`,
    attrs: {
      "name": "modals-fade",
      "appear": ""
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.visible,
      expression: "visible"
    }],
    staticClass: "modal",
    class: {
      scrollOutside: _vm.scrollOutside
    },
    style: {
      'z-index': _vm.zIndex
    }
  }, [_c('div', {
    staticClass: "modal-bg",
    on: {
      "click": function ($event) {
        if ($event.target !== $event.currentTarget) return null;
        return _vm.close.apply(null, arguments);
      }
    }
  }), _c('div', {
    staticClass: "modal-body",
    style: {
      width: `${_vm.width}px`
    }
  }, [_c('div', {
    staticClass: "modal-btn-close",
    class: {
      dark: _vm.advanced && _vm.rightDark
    },
    on: {
      "click": _vm.close
    }
  }, [_c('svg', {
    attrs: {
      "width": "21",
      "height": "20",
      "viewBox": "0 0 21 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M8.14318 13.5348L10.4998 11.1782L12.8565 13.5348L14.0348 12.3565L11.6782 9.99984L14.0348 7.64318L12.8565 6.46484L10.4998 8.82151L8.14318 6.46484L6.96484 7.64318L9.32151 9.99984L6.96484 12.3565L8.14318 13.5348Z",
      "fill": "black",
      "fill-opacity": "0.4"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.4993 18.3337C15.0943 18.3337 18.8327 14.5953 18.8327 10.0003C18.8327 5.40533 15.0943 1.66699 10.4993 1.66699C5.90435 1.66699 2.16602 5.40533 2.16602 10.0003C2.16602 14.5953 5.90435 18.3337 10.4993 18.3337ZM10.4993 3.33366C14.1752 3.33366 17.166 6.32449 17.166 10.0003C17.166 13.6762 14.1752 16.667 10.4993 16.667C6.82352 16.667 3.83268 13.6762 3.83268 10.0003C3.83268 6.32449 6.82352 3.33366 10.4993 3.33366Z",
      "fill": "black",
      "fill-opacity": "0.4"
    }
  })])]), _c('div', {
    staticClass: "modal-body__content"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "modal-header__title",
    class: _vm.titlePosition
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")])]), _c('div', {
    ref: "content",
    staticClass: "modal-content",
    class: {
      advanced: _vm.advanced
    }
  }, [_vm._t("default"), _c('focus')], 2), _vm.actionsComputed.length ? _c('div', {
    staticClass: "modal-footer"
  }, _vm._l(_vm.actionsComputed, function (action, i) {
    return _c('button', {
      key: i,
      staticClass: "action-button",
      class: [action.class, {
        loading: action.loading
      }],
      attrs: {
        "disabled": action.disabled
      },
      on: {
        "click": function ($event) {
          $event.preventDefault();
          return _vm.onAction(action);
        }
      }
    }, [action.loading ? _c('div', {
      staticClass: "loading-overlay"
    }, [_c('loading-spinner', {
      staticClass: "spinner"
    })], 1) : _vm._e(), _c('span', {
      staticClass: "content"
    }, [_vm._v(" " + _vm._s(action.text) + " ")])]);
  }), 0) : _vm._e()]), _vm.advanced ? _c('div', {
    staticClass: "modal-body__right",
    class: {
      wide: _vm.wide,
      dark: _vm.rightDark
    }
  }, [_vm._t("right")], 2) : _vm._e()])])])], 1) : _vm._e()], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };