export default {
    red: { color: "#E0A1B5" },
    blue: { color: "#6D8A9F" },
    yellow: { color: "#FFA83F" },
    fiolet: { color: "#715D9A" },
    green: { color: "#5D9A8F" },
    pink: { color: "#AC5E96" },
    orange: { color: "#F98757" },
    darkblue: { color: "#215063" },
    lightgreen: { color: "#8eac5e" },
    lightyellow: { color: "#b5b138" },
    brown: { color: "#5E3800" },
    alt: {
        cyan: { color: "#41D0BF" },
        blue: { color: "#4172D0" },
        red: { color: "#D04141" },
        violet: { color: "#8041D0" },
    },
    hexToRgb(hex) {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? {
                  r: parseInt(result[1], 16),
                  g: parseInt(result[2], 16),
                  b: parseInt(result[3], 16),
              }
            : null;
    },
};
