export default {
    format(input, variant = "en", min = 0) {
        const number = Number(input);
        if (number < min) {
            return number;
        }
        const precision = 1;
        const variants = {
            en: {
                1000: (v) => `${v}K`,
                1000000: (v) => `${v}M`,
                1000000000: (v) => `${v}B`,
            },
            ru: {
                1000: (v) => `${v} тыс`,
                ////1000000: (v) => `${v} млн`,
                1000000: (v) => `${v} M`, //! &:
                1000000000: (v) => `${v} млрд`,
            },
        };
        const preset = variants[variant];
        const step = Object.keys(preset)
            .sort()
            .reverse()
            .find((step) => {
                return number >= step;
            });
        if (step) {
            const tenPow = Math.pow(10, precision);
            const val = Math.round((number * tenPow) / step) / tenPow;
            return preset[step](val);
        }
        return number;
    },
    divided(input) {
        return Number(input).toLocaleString();
    },
};
export function plural(number, [one, two, five = null]) {
    five = five || two;
    let n = Math.abs(number);
    while (n % 1 !== 0) {
        n = n * 10;
    }
    n %= 100;

    const getResult = (format) => {
        return format.replace("{v}", number);
    };
    if (n >= 5 && n <= 20) {
        return getResult(five);
    }
    n %= 10;
    if (n === 1) {
        return getResult(one);
    }
    if (n >= 2 && n <= 4) {
        return getResult(two);
    }
    return getResult(five);
}
